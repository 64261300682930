export const productos = (state) => {
  return state.productos;
};

export const getPaginacion = (state) => {
  return state.paginacion;
};

export const getSegments = (state) => {
  return state.segments;
};

export const getProductUse = (state) => {
  return state.productUse;
};

export const getProductTypeId = (state) => {
  return state.productTypeId;
};

export const statusList = (state) => {
  return state.statuslist;
};

export const brokersList = (state) => {
  return state.brokersList;
};

export const behaviour = (state) => {
  return state.behaviour;
};

export const company = (state) => {
  try {
    return JSON.parse(state.company);
  } catch {
    return state.company;
  }
};

export const tarifas = (state) => {
  return state.tarifas;
};

export const companies = (state) => {
  return state.companies;
};

export const nemotecnicos = (state) => {
  return state.nemotecnicos;
};

export const vigencias = (state) => {
  try {
    return JSON.parse(state.vigencias);
  } catch {
    return state.vigencias;
  }
};

export const exclusividades = (state) => {
  return state.exclusivityList;
};

export const planes = (state) => {
  try {
    return JSON.parse(state.planes);
  } catch {
    return state.planes;
  }
};
