import { baseHttp } from "@/helpers/baseHttp.js";
import { mClickHeader } from "@/store/resources/headers.js";
import { endpoints } from "@/helpers/endpoints.js";
import axios from "axios";

export const GET = ({ commit, getters }) => {
  const url = `${getters.getEndpoint}`;
  return baseHttp({
    url,
    method: "GET",
    commit,
    commitMethod: "setItems",
    messages: {
      error: "No se ha logrado recuperar el listado de compañías.",
    },
  });
};

export const POST = ({ commit, getters }, formData) => {
  const url = `${getters.getEndpoint}`;
  return baseHttp({
    url,
    method: "POST",
    commit,
    formData,
    messages: {
      success: "Se ha creado una nueva compañía satisfactoriamente!",
      error: "Ha ocurrido un error al intentar guardar una nueva compañía.",
    },
  });
};

export const PUT = ({ commit, getters }, payload) => {
  const url = `${getters.getEndpoint}/${payload.id}`;
  return baseHttp({
    url,
    method: "PUT",
    commit,
    formData: payload,
    messages: {
      success: "Se ha actualizado la compañía correctamente!",
      error: "Ha ocurrido un error al intentar actualizar la compañía.",
    },
  });
};

export const DELETE = ({ commit, getters }, id) => {
  const url = `${getters.getEndpoint}/${id}`;
  return baseHttp({
    url,
    method: "DELETE",
    commit,
    messages: {
      success: "Se ha eliminado la compañía correctamente!",
      error: "Ha ocurrido un error al intentar eliminar una compañía.",
    },
  });
};

export const getTiposDocumentos = () => {
  const url = endpoints.documentos;
  return new Promise((resolve, reject) => {
    axios({ url, method: "GET", headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data.data.data;
        resolve(result);
      })
      .catch((err) => {
        reject(
          err?.response?.data ??
            "No se ha logrado recuperar el listado de tipos de documentos."
        );
      });
  });
};
