import axios from "axios";
import { mClickHeader } from "@/store/resources/headers.js";
import { endpoints } from "@/helpers/endpoints.js";

//Get
export const getCompanies = () => {
  const url = endpoints.integrations.empresas;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data?.empresas;
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: "Ha ocurrido un error al intentar recuperar el listado de empresas",
        };
        reject(rejectData);
      });
  });
};
export const getServices = () => {
  const url = endpoints.integrations.services;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err.response.data.data.lista_mensaje[0].mensaje);
      });
  });
};
export const getHomologaciones = ({ commit }, payload) => {
  const url = endpoints.integrations.homologaciones;
  const params = {
    page: payload?.page ?? 1,
    per_page: payload?.limit ?? 10,
  };
  if (payload?.id_empresa) {
    params.id_empresa = payload.id_empresa;
  }
  if (payload?.search) {
    params.filter = payload?.search;
  }

  return new Promise((resolve, reject) => {
    axios({
      url,
      params,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        commit("setHItems", result.homologacion);
        resolve(result);
      })
      .catch((error) => {
        reject(error.response.data?.data?.lista_mensaje[0]?.mensaje);
      });
  });
};
export const getFormats = () => {
  const url = endpoints.integrations.formatos;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        const result = res.data.data.formatos;
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            "Ha ocurrido un error al intentar recuperar los formatos del servidor."
          ),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

// POST ACTIONS
export const CrearEmpresa = (context, data) => {
  const url = endpoints.integrations.empresas;
  return new Promise((resolve, reject) => {
    axios({
      data,
      url,
      method: "POST",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve({
          info: "Se ha creado la empresa satisfactoriamente!",
          result: response.data?.data,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error("Ha ocurrido un error al intentar crear una empresa"),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

export const CrearServicio = (context, data) => {
  const url = endpoints.integrations.services;
  return new Promise((resolve, reject) => {
    axios({
      data,
      url,
      method: "POST",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          info: "Se ha creado el servicio satisfactoriamente!",
          result,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            error.response.data?.data?.lista_mensaje[0]?.mensaje ||
              "Ha ocurrido un error al intentar crear un servicio"
          ),
        };
        reject(rejectData);
      });
  });
};

export const CrearHomologacion = (context, data) => {
  const url = endpoints.integrations.homologaciones;
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data, headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data.data;
        resolve({
          info: "Se ha creado una homologación satisfactoriamente!",
          result,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            error.response.data?.data?.lista_mensaje[0]?.mensaje ||
              "Ha ocurrido un error al intentar crear una homologacion."
          ),
        };
        reject(rejectData);
      });
  });
};
export const CrearFormato = (context, data) => {
  const url = endpoints.integrations.formatos;
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          info: "Se ha creado un formato satisfactoriamente!",
          result: response.data?.data,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            "Ha ocurrido un error al intentar crear un nuevo formato"
          ),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};
export const CargaMasiva = (context, data) => {
  const url = endpoints.integrations.hCargaMasiva;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "POST",
      data,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve({
          info: "Se ha cargado el archivo masivo de homologaciones correctamente!",
          result: response.data?.data,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            "Ha ocurrido un error al intentar cargar un archivo masivo."
          ),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

// DELETE ACTIONS
export const deleteEmpresa = (context, id) => {
  const url = `${endpoints.integrations.empresas}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve({
          info: "Se ha eliminado la empresa correctamente!",
          result: response.data?.data,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            "Ha ocurrido un error al intentar eliminar la empresa"
          ),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

export const deleteServicio = (context, id) => {
  const url = `${endpoints.integrations.services}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          info: "Se ha eliminado el servicio correctamente!",
          result,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            error.response.data?.data?.lista_mensaje[0]?.mensaje ||
              "Ha ocurrido un error al intentar eliminar el servicio"
          ),
        };
        reject(rejectData);
      });
  });
};
export const deleteHomologacion = (context, id) => {
  const url = `${endpoints.integrations.homologaciones}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve({
          info: "Se ha eliminado la Homologación correctamente!",
          response,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            error.response.data?.data?.lista_mensaje[0]?.mensaje ||
              "Ha ocurrido un error al intentar eliminar una homologacion"
          ),
        };
        reject(rejectData);
      });
  });
};

export const deleteFormato = (context, id) => {
  const url = `${endpoints.integrations.formatos}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve({
          info: "Se ha eliminado el formato correctamente!",
          result: response.data?.data,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            "Ha ocurrido un error al intentar eliminar un formato."
          ),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

// UPDATE ACTIONS
export const UpdateEmpresa = (context, payload) => {
  const { id, ...data } = payload;
  const url = `${endpoints.integrations.empresas}/${id}`;
  return new Promise((resolve, reject) => {
    axios({ url, data, method: "PUT", headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha actualizado la empresa correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: new Error(
            "Ha ocurrido un error al intentar actualizar la empresa"
          ),
        };
        reject(rejectData);
      });
  });
};

export const UpdatHomolog = (context, data) => {
  const url = `${endpoints.integrations.homologaciones}/${data.id}`;
  return new Promise((resolve, reject) => {
    axios({ url, data, method: "PUT", headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: "Se ha actualizado la homologación correctamente!",
        });
      })
      .catch((error) => {
        reject(error.response.data?.data?.lista_mensaje[0]?.mensaje);
      });
  });
};
export const UpdateFormato = (context, payload) => {
  const { id, ...data } = payload;
  const url = `${endpoints.integrations.formatos}/${id}`;
  return new Promise((resolve, reject) => {
    axios({ url, data, method: "PUT", headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha actualizado un formato correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            "Ha ocurrido un error al intentar actualizar un formato."
          ),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};
export const UpdateServicio = (context, payload) => {
  const url = `${endpoints.integrations.services}/${payload.id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      data: payload.data,
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          info: "Se ha actualizado el servicio correctamente!",
          result,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            error.response.data?.data?.lista_mensaje[0]?.mensaje ||
              "Ha ocurrido un error al intentar actualizar un servicio."
          ),
        };
        reject(rejectData);
      });
  });
};

//MATRIZ GET

export const getMatrizByCompany = ({ commit }, payload) => {
  const url = `${endpoints.integrations.matriz.matriz}/matrices/${payload.id}`;
  const params = {
    page: payload?.page ?? 1,
    per_page: payload?.limit ?? 10,
  };

  return new Promise((resolve, reject) => {
    axios({
      url,
      params,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data;
        if (result.data) {
          commit("setMItems", result.data);
        } else {
          commit("setMItems", []);
        }
        resolve(result);
      })
      .catch((error) => {
        reject(error.response.data?.data?.lista_mensaje[0]?.mensaje);
      });
  });
};

export const getAllMatriz = ({ commit }, payload) => {
  const url = endpoints.integrations.matriz.matriz;
  const params = {
    page: payload?.page ?? 1,
    per_page: payload?.limit ?? 10,
  };
  if (payload?.search) {
    params.filter = payload?.search;
  }

  return new Promise((resolve, reject) => {
    axios({
      url,
      params,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data;
        commit("setMItems", result.data);
        resolve(result);
      })
      .catch((error) => {
        reject(error.response.data?.data?.lista_mensaje[0]?.mensaje);
      });
  });
};

export const getCompaniesAll = () => {
  const url = endpoints.integrations.matriz.companies;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: "Ha ocurrido un error al intentar recuperar el listado de compañias",
        };
        reject(rejectData);
      });
  });
};

export const getProductos = (context, payload) => {
  const url = `${endpoints.integrations.matriz.productos}${payload.id}`;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: "Ha ocurrido un error al intentar recuperar el listado de productos",
        };
        reject(rejectData);
      });
  });
};

export const getDeducibles = (context, payload) => {
  const url = `${endpoints.integrations.matriz.deducibles}${payload.id}`;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: "Ha ocurrido un error al intentar recuperar el listado de deducibles",
        };
        reject(rejectData);
      });
  });
};

export const getMarcas = () => {
  const url = endpoints.integrations.matriz.marcas;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: "Ha ocurrido un error al intentar recuperar el listado de marcas",
        };
        reject(rejectData);
      });
  });
};

export const getModelos = (context, payload) => {
  const url = `${endpoints.integrations.matriz.modelos}${payload.id}`;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: "Ha ocurrido un error al intentar recuperar el listado de modelos",
        };
        reject(rejectData);
      });
  });
};

//POST
export const CrearMatriz = (context, data) => {
  const url = endpoints.integrations.matriz.matriz;
  return new Promise((resolve, reject) => {
    axios({
      data,
      url,
      method: "POST",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve({
          info: "Se ha creado la matriz satisfactoriamente!",
          result: response.data?.data,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error("Ha ocurrido un error al intentar crear una matriz"),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

//DELETE
export const deleteMatriz = (context, id) => {
  const url = `${endpoints.integrations.matriz.matriz}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve({
          info: "Se ha eliminado la matriz correctamente!",
          result: response.data?.data,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            "Ha ocurrido un error al intentar eliminar la matriz."
          ),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

//PATCH
export const UpdateMatriz = (context, payload) => {
  const url = endpoints.integrations.matriz.matriz;
  return new Promise((resolve, reject) => {
    axios({
      method: "PATCH",
      data: payload,
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          info: "Se ha actualizado la matriz correctamente!",
          result,
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            error.response.data?.data?.lista_mensaje[0]?.mensaje ||
              "Ha ocurrido un error al intentar actualizar la matriz."
          ),
        };
        reject(rejectData);
      });
  });
};
