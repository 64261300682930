import { homeBreadCrumb, integratorBreadCrumb } from "./breadcrumbs.js";

export default [
  {
    ref: "201",
    title: "Configurador de empresas",
    hideTitle: false,
    icon: "domain",
    breadcrumb: [
      homeBreadCrumb,
      integratorBreadCrumb,
      {
        text: "Empresas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "202",
    title: "Configurador de servicios",
    hideTitle: false,
    icon: "account-wrench",
    breadcrumb: [
      homeBreadCrumb,
      integratorBreadCrumb,
      {
        text: "Servicios",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "203",
    title: "Configurador de homologaciones",
    hideTitle: false,
    icon: "approximately-equal",
    breadcrumb: [
      homeBreadCrumb,
      integratorBreadCrumb,
      {
        text: "Homologaciones",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "204",
    title: "Configurador de formatos",
    hideTitle: false,
    icon: "folder-information",
    breadcrumb: [
      homeBreadCrumb,
      integratorBreadCrumb,
      {
        text: "Formatos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "205",
    title: "Configurador de Matriz Qualitas",
    hideTitle: false,
    icon: "matrix",
    breadcrumb: [
      homeBreadCrumb,
      integratorBreadCrumb,
      {
        text: "Matriz Qualitas",
        disabled: true,
        href: "#",
      },
    ],
  },
];
