import * as mutations from "./mutations";
import * as actions from "./actions";
import * as getters from "./getters";

import Endpoints from "@/helpers/endpoints.js";
import { brandModelKeys } from "@/store/resources/maintenanceKeys.js";
import * as esText from "@/assets/lang/es/text.json";

export default {
  namespaced: true,
  state: {
    items: [],
    brands: [],
    endpoints: Endpoints.cruds.brandAndModels,
    keys: brandModelKeys,
    text: esText.data.cruds.brandAndModel,
  },
  actions,
  mutations,
  getters,
};
