import axios from "axios";
import { mClickHeader } from "@/store/resources/headers.js";
import { endpoints } from "@/helpers/endpoints.js";

//METODOS CREATE
export const CreateRamo = async (context, data) => {
  const url = endpoints.cruds.ramos;
  return new Promise((resolve, reject) => {
    axios({ method: "POST", data, url, headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: "Se ha creado un nuevo ramo satisfactoriamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: "Ha ocurrido un error al intentar crear un ramo.",
        };
        reject(rejectData);
      });
  });
};

export const CreateSegment = (context, data) => {
  // Payload => Objeto con ramo y segmento_descripcion
  const url = endpoints.cruds.segmentos;
  return new Promise((resolve, reject) => {
    axios({ url, method: "POST", data, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha creado el segmento satisfactoriamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: new Error("No se ha podido crear el segmento"),
        };
        reject(rejectData);
      });
  });
};

export const CreateProductUse = (context, data) => {
  // Payload => Objeto con ramo y uso_producto_descripcion
  const url = endpoints.cruds.usoProducto;
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha creado el uso de producto correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error("No se ha logrado crear el nuevo uso del producto."),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

export const CreateInsuranceBroker = ({ dispatch }, payload = {}) => {
  const url = endpoints.cruds.corredor;
  // Payload => Objeto con ramo y uso_producto_descripcion
  const formData = {
    corredor_descripcion: payload.corredor_descripcion
      ? payload.corredor_descripcion
      : "",
    tipo_documento: payload.tipo_documento ? payload.tipo_documento : "",
    numero_documento: payload.numero_documento ? payload.numero_documento : "",
    poliza_garantia: payload.poliza_garantia ? payload.poliza_garantia : "",
    poliza_responsabilidad: payload.poliza_responsabilidad
      ? payload.poliza_responsabilidad
      : "",
    diversificacion_produccion: payload.diversificacion_produccion
      ? payload.diversificacion_produccion
      : "",
    porcentaje_corredor: payload.porcentaje_corredor
      ? payload.porcentaje_corredor
      : 0,
    logo: {
      mime: payload.logo.mime ? payload.logo.mime : "",
      nombre_archivo: payload.logo.nombre_archivo
        ? payload.logo.nombre_archivo
        : "",
      archivo: payload.logo.archivo ? payload.logo.archivo : "",
    },
    firma: {
      mime: payload.firma.mime ? payload.firma.mime : "",
      nombre_archivo: payload.firma.nombre_archivo
        ? payload.firma.nombre_archivo
        : "",
      archivo: payload.firma.archivo ? payload.firma.archivo : "",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(url, formData, { headers: mClickHeader.headers })
      .then(async () => {
        await dispatch("REQUEST_INSURANCE_BROKER");
        resolve("Se ha creado el corredor de seguros correctamente!");
      })
      .catch(() => {
        reject(
          new Error("No se ha logrado crear el nuevo corredor de seguros.")
        );
      });
  });
};

export const CrearMateria = async (context, formData) => {
  const path = endpoints.cruds.InsureMateria;
  return new Promise((resolve, reject) => {
    axios
      .post(path, formData, { headers: mClickHeader.headers })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

export const CrearTipoProducto = async (context, data) => {
  const url = endpoints.cruds.tipoProducto.base;
  try {
    const response = await axios({
      method: "POST",
      url,
      data,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha creado el tipo de producto satisfactoriamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar crear un tipo de producto",
    };
    throw rejectData;
  }
};

export const CrearPais = async (context, data) => {
  const url = endpoints.cruds.paises;
  try {
    const response = await axios({
      data,
      url,
      method: "POST",
      headers: mClickHeader.headers,
    });
    return {
      result: response.data.data,
      info: "Se ha creado el país satisfactoriamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response.data,
      info: "Ha ocurrido un error al intentar crear un país.",
    };
    throw rejectData;
  }
};

export const CrearMoneda = async (context, data) => {
  const url = endpoints.cruds.monedas;
  try {
    const response = await axios({
      method: "POST",
      url,
      data,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data.data,
      info: "Se ha creado una nueva moneda satisfactoriamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response.data,
      info: "Ha ocurrido un error al intentar crear una nueva moneda",
    };
    throw rejectData;
  }
};

export const CrearCobertura = async (context, data) => {
  const url = endpoints.cruds.coberturas;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url,
      data,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: "Se ha creado la cobertura satisfactoriamente!",
        });
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar crear la cobertura",
        };
        reject(rejectData);
      });
  });
};

export const CrearPlan = async ({ commit }, payload) => {
  const url = endpoints.cruds.Plans;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "POST",
      data: payload,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data;
        commit("setPlanes", result);
        resolve({ result, info: "Se ha creado un plan satisfactoriamente!" });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: new Error("No se ha logrado crear un nuevo plan."),
        };
        reject(rejectData);
      });
  });
};

export const CrearVigencia = async (context, data) => {
  const url = endpoints.cruds.vigencias;
  try {
    const response = await axios({
      method: "POST",
      data,
      url,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha creado una vigencia satisfactoriamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar crear la vigencia",
    };
    throw rejectData;
  }
};

export const CrearDocumentAnexo = (context, data) => {
  const url = endpoints.cruds.docAnexo;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url,
      data,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: "Se ha creado un documento anexo satisfactoriamente!",
        });
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar crear un documento",
        };
        reject(rejectData);
      });
  });
};

export const CrearDocumentEmision = async (context, data) => {
  const url = endpoints.cruds.docEmision;
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      data,
      url,
      headers: mClickHeader.headers,
    }).then((response) => {
      //console.log("r",response);
      const result = response.data.data;
      resolve({
        result,
        info: "Se ha creado un nuevo documento satisfactoriamente!",
      }).catch((error) => {
        //console.log("e",error);
        const result = error.response.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar guardar un documento.",
        };
        reject(rejectData);
      });
    });
  });
};

export const CrearTipoContratante = async (context, data) => {
  const url = endpoints.cruds.tipoContratante;
  try {
    const response = await axios({
      method: "POST",
      url,
      data,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha creado el tipo de contratante satisfactoriamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar crear un tipo de contratante",
    };
    throw rejectData;
  }
};

export const CrearTipoCobertura = async (context, data) => {
  const url = endpoints.cruds.coberturaTipos2;
  try {
    const response = await axios({
      url,
      data,
      method: "POST",
      headers: mClickHeader.headers,
    });
    return {
      info: "Se ha creado el tipo de cobertura satisfactoriamente!",
      result: response.data?.data,
    };
  } catch (error) {
    const rejectData = {
      info: "Ha ocurrido un error al intentar crear un tipo de cobertura.",
      result: error.response?.data,
    };
    throw rejectData;
  }
};

export const CrearPoliza = async (context, data) => {
  const url = endpoints.cruds.polizas;
  try {
    const response = await axios({
      method: "POST",
      url,
      data,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha creado una póliza satisfactoriamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar crear una póliza",
    };
    throw rejectData;
  }
};

export const CreateDataBasica = async (context, payload) => {
  const path = endpoints.productos.base;
  return new Promise((resolve) => {
    axios
      .post(path, payload, {
        headers: mClickHeader.headers,
      })
      .then((respuesta) => {
        resolve(respuesta);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};
