import { homeBreadCrumb, crudBreadCrumb } from "./breadcrumbs.js";

export const Mantenedores = [
  // Caracteristicas para paginas de mantenedores
  {
    ref: "101",
    title: "Gestor de ramos",
    hideTitle: false,
    icon: "vector-intersection",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Ramos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "102",
    title: "Gestor paises",
    hideTitle: false,
    icon: "flag",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Paises",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "104",
    title: "Gestor para materia asegurada",
    hideTitle: false,
    icon: "palette-swatch",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Materia asegurada",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "105",
    title: "Gestor de tipos de productos",
    hideTitle: false,
    icon: "star",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipo de producto",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "106",
    title: "Gestor de monedas",
    hideTitle: false,
    icon: "cash-multiple",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Monedas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "107",
    title: "Gestor de coberturas",
    hideTitle: false,
    icon: "bus-stop-covered",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Coberturas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "108",
    title: "Gestor de planes",
    hideTitle: false,
    icon: "notebook-multiple",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Planes",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "109",
    title: "Gestor de segmentos",
    hideTitle: false,
    icon: "segment",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Segmentos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "110",
    title: "Gestor de estado para solicitudes",
    hideTitle: false,
    icon: "list-status",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Est. Solicitudes",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "111",
    title: "Gestor de vigencias",
    hideTitle: false,
    icon: "book-clock",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Vigencias",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "112",
    title: "Gestor para documentos anexos",
    hideTitle: false,
    icon: "file-document-multiple",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Doc. Anexos",
        disabled: true,
        href: "#",
      },
    ],
  },
  /* {
    ref: "113",
    title: "Gestor para documentos en emisión",
    hideTitle: false,
    icon: "text-box-check",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Doc. Emisión",
        disabled: true,
        href: "#",
      },
    ],
  }, */
  {
    ref: "114",
    title: "Gestor de pólizas",
    hideTitle: false,
    icon: "human-male-board-poll",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Pólizas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "115",
    title: "Gestor de tipos coberturas",
    hideTitle: false,
    icon: "format-list-bulleted-type",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipos coberturas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "116",
    title: "Gestor para tipos de contratantes",
    hideTitle: false,
    icon: "file-document-edit",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipos contratantes",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "117",
    title: "Gestor de financiera",
    hideTitle: false,
    icon: "finance",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Financiera",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "118",
    title: "Gestor para usos de productos",
    hideTitle: false,
    icon: "archive-cog-outline",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Uso de producto",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "119",
    title: "Gestor para corredores de seguros",
    hideTitle: false,
    icon: "shield-account-variant-outline",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Corredor de seguro",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "120",
    title: "Gestor para tipos de pólizas",
    hideTitle: false,
    icon: "clipboard-text-multiple-outline",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Tipos pólizas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "121",
    title: "Gestor de colores para vehículos",
    hideTitle: false,
    icon: "palette-outline",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Color de vehículos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "122",
    title: "Gestor de años",
    hideTitle: false,
    icon: "calendar-edit",
    breadcrumb: [
      homeBreadCrumb,
      crudBreadCrumb,
      {
        text: "Años",
        disabled: true,
        href: "#",
      },
    ],
  },
];

export default Mantenedores;
