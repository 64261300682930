import Mantenedores from "./crudData.js";
import Integraciones from "./integratorData.js";

import { homeBreadCrumb } from "./breadcrumbs.js";

export default [
  {
    ref: "0",
    title: "",
    hideTitle: true,
    breadcrumb: [
      {
        text: "Home",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "1",
    title: "Gestor de Productos",
    hideTitle: false,
    icon: "archive-settings-outline",
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Productos",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "2",
    title: "Gestor de Solicitudes",
    hideTitle: false,
    icon: "receipt-text-outline",
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Solicitudes",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "3",
    title: "Asignación y distribución de pólizas",
    hideTitle: false,
    icon: "file-sign",
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Pólizas",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "4",
    title: "Gestor de compañías",
    hideTitle: false,
    icon: "domain",
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Compañías",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "5",
    title: "Configurador de marcas y modelos",
    hideTitle: false,
    icon: "expand-all-outline",
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Marcas y modelos",
        disabled: true,
        href: "#",
      },
    ],
  },

  {
    ref: "10",
    title: "Gestionar un producto",
    icon: "archive",
    hideTitle: true,
    container: "fluid",
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Productos",
        disabled: false,
        href: "/#/productos",
      },
      {
        text: "Detalle",
        disabled: true,
        href: "#",
      },
    ],
  },

  {
    ref: "100",
    title: "Gestores de contenido",
    icon: "table-cog",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Mantenedores",
        disabled: true,
        href: "#",
      },
    ],
  },
  {
    ref: "200",
    title: "Gestores de Integración",
    icon: "integrated-circuit-chip",
    hideTitle: false,
    breadcrumb: [
      homeBreadCrumb,
      {
        text: "Integración",
        disabled: true,
        href: "#",
      },
    ],
  },

  ...Mantenedores,
  ...Integraciones,
];
