export default [
  {
    ref: "101",
    path: "branches-maintainer",
    disabled: false,
    title: "Ramos",
    name: "Ramos",
    text: "Gestor de ramos",
    type: "box",
    icons: {
      menu: "vector-intersection",
      index: "vector-intersection",
    },
  },
  {
    ref: "102",
    path: "countries-maintainer",
    disabled: false,
    title: "Países",
    name: "Países",
    text: "Gestor de países",
    type: "box",
    icons: {
      menu: "flag",
      index: "flag",
    },
  },
  {
    ref: "103",
    path: "insured-material-maintainer",
    disabled: false,
    title: "Materias aseg.",
    name: "MA",
    text: "Gestor de materias aseguradas",
    type: "box",
    icons: {
      menu: "palette-swatch",
      index: "palette-swatch",
    },
  },
  {
    ref: "104",
    path: "insured-material-v2-maintainer",
    disabled: false,
    title: "Materias aseg. V2",
    name: "MA V2",
    text: "Gestor de materias aseguradas, versión 2",
    type: "box",
    icons: {
      menu: "palette-swatch",
      index: "palette-swatch",
    },
  },
  {
    ref: "105",
    path: "product-type-maintainer",
    disabled: false,
    title: "Tipos productos",
    name: "TPS",
    text: "Gestor de tipos de productos",
    type: "box",
    icons: {
      menu: "star",
      index: "star",
    },
  },
  {
    ref: "106",
    path: "currency-maintainer",
    disabled: false,
    title: "Monedas",
    name: "Monedas",
    text: "Gestor de moneda",
    type: "box",
    icons: {
      menu: "cash-multiple",
      index: "cash-multiple",
    },
  },
  {
    ref: "107",
    path: "coverage-maintainer",
    disabled: false,
    title: "Coberturas",
    name: "Coberturas",
    text: "Gestor de coberturas",
    type: "box",
    icons: {
      menu: "bus-stop-covered",
      index: "bus-stop-covered",
    },
  },
  {
    ref: "108",
    path: "plan-maintainer",
    disabled: false,
    title: "Planes",
    name: "Planes",
    text: "Gestor de planes (deducibles)",
    type: "box",
    icons: {
      menu: "notebook-multiple",
      index: "notebook-multiple",
    },
  },
  {
    ref: "109",
    path: "segment-maintainer",
    disabled: false,
    title: "Segmentos",
    name: "Segmentos",
    text: "Gestor de segmentos",
    type: "box",
    icons: {
      menu: "segment",
      index: "segment",
    },
  },
  {
    ref: "110",
    path: "application-status-maintainer",
    disabled: false,
    title: "Est. solicitudes",
    name: "ES",
    text: "Gestor para los estados de solicitudes",
    type: "box",
    icons: {
      menu: "list-status",
      index: "list-status",
    },
  },
  {
    ref: "111",
    path: "validity-maintainer",
    disabled: false,
    title: "Vigencias",
    name: "Vigencias",
    text: "Gestor de vigencias",
    type: "box",
    icons: {
      menu: "book-clock",
      index: "book-clock",
    },
  },
  {
    ref: "112",
    path: "attached-document-maintainer",
    disabled: false,
    title: "Doc. anexos",
    name: "DA",
    text: "Gestor para documentos anexos",
    type: "box",
    icons: {
      menu: "file-document-multiple",
      index: "file-document-multiple",
    },
  },
  /* {
    ref: "113",
    path: "issuance-document-maintainer",
    disabled: false,
    title: "Doc. emisión",
    name: "DE",
    text: "Gestor para documentos en emisión",
    type: "box",
    icons: {
      menu: "text-box-check",
      index: "text-box-check",
    },
  }, */
  {
    ref: "114",
    path: "policy-maintainer",
    disabled: false,
    title: "Pólizas",
    name: "Pólizas",
    text: "Gestor de pólizas",
    type: "box",
    icons: {
      menu: "human-male-board-poll",
      index: "human-male-board-poll",
    },
  },
  {
    ref: "115",
    path: "coverage-type-maintainer",
    disabled: false,
    title: "Tipos coberturas",
    name: "TCB",
    text: "Gestor para tipos de coberturas",
    type: "box",
    icons: {
      menu: "format-list-bulleted-type",
      index: "format-list-bulleted-type",
    },
  },
  {
    ref: "116",
    path: "contractor-type-maintainer",
    disabled: false,
    title: "Tipos contratantes",
    name: "TCT",
    text: "Gestor para tipos de contratantes",
    type: "box",
    icons: {
      menu: "file-document-edit",
      index: "file-document-edit",
    },
  },
  {
    ref: "117",
    path: "financial-maintainer",
    disabled: false,
    title: "Financiera",
    name: "Financiera",
    text: "Gestor de financiera",
    type: "box",
    icons: {
      menu: "finance",
      index: "finance",
    },
  },
  {
    ref: "118",
    path: "product-use-maintainer",
    disabled: false,
    title: "Uso producto",
    name: "UP",
    text: "Gestor para usos de productos",
    type: "box",
    icons: {
      menu: "archive-cog-outline",
      index: "archive-cog-outline",
    },
  },
  {
    ref: "119",
    path: "insurance-brokers-maintainer",
    disabled: false,
    title: "Corredor seguros",
    name: "CS",
    text: "Gestor para corredores de seguros",
    type: "box",
    icons: {
      menu: "shield-account-variant-outline",
      index: "shield-account-variant-outline",
    },
  },
  {
    ref: "120",
    path: "policy-type-maintainer",
    disabled: false,
    title: "Tipos pólizas",
    name: "TPZ",
    text: "Gestor para tipos de pólizas",
    type: "box",
    icons: {
      menu: "clipboard-text-multiple-outline",
      index: "clipboard-text-multiple-outline",
    },
  },
  {
    ref: "121",
    path: "colors-vehicle-maintainer",
    disabled: false,
    title: "Colores vehículos",
    name: "CVC",
    text: "Gestor para colores de vehículos",
    type: "box",
    icons: {
      menu: "palette-outline",
      index: "palette-outline",
    },
  },
  {
    ref: "122",
    path: "years-maintainer",
    disabled: false,
    title: "Años",
    name: "Años",
    text: "Gestor de años",
    type: "box",
    icons: {
      menu: "calendar-edit",
      index: "calendar-edit",
    },
  },
];
