import axios from "axios";
import { mClickHeader } from "@/store/resources/headers.js";
import endpoints from "@/helpers/endpoints";

const path = endpoints.solicitudes;

export const loadSolicitudes = async ({ commit }, queryparams) => {
  try {
    let limit = !queryparams ? 11 : queryparams;
    const url = `${path}?limit=${limit}`;
    var respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    respuesta = respuesta.data.data.solicitudes;
    if (respuesta.length > 10) {
      respuesta.pop(); //quitamos el ultimo valor del arreglo para que solo sean 10
      commit("setPaginacion", true);
    } else {
      commit("setPaginacion", false);
    }
    commit("loadSolicitud", respuesta);
  } catch (error) {
    commit(
      "setSnackbar",
      {
        active: true,
        text: error.response.data.message,
        top: true,
        right: true,
        color: "error",
      },
      { root: true }
    );

    console.log("error ", error);
  }
};

export const crearSolicitud = async ({ commit, dispatch }, data) => {
  try {
    let respuesta = await axios({
      method: "POST",
      data: { ...data },
      url: path,
      headers: mClickHeader.headers,
    });
    if (respuesta.data.success) {
      dispatch("loadSolicitudes");
      // commit("setSolicitud", respuesta.data.data.solicitud);
      return true;
    }
  } catch (error) {
    commit(
      "setSnackbar",
      {
        active: true,
        text: error.response.data.message,
        top: true,
        right: true,
        color: "error",
      },
      { root: true }
    );

    console.log("Error=====>", error);
    return false;
  }
};

export const actualizarSolicitud = async ({ commit }, data) => {
  try {
    const url = `${path}/${id}`;
    let id = data.id;
    let respuesta = await axios({
      method: "PUT",
      url,
      data: { ...data },
      headers: mClickHeader.headers,
    });
    if (respuesta.data.success) {
      let url;
      if (data.cambiar_documento == "no") {
        url = data.url;
      } else {
        url = respuesta.data.data.solicitud.url;
      }
      let registroUpdate = {
        id: data.id,
        fecha_solicitud: data.fecha_solicitud,
        nombre: data.nombre,
        estado: data.estado,
        estado_descripcion: respuesta.data.data.solicitud.estado_descripcion,
        usuario: data.usuario,
        asunto: data.asunto,
        extension: data.extension,
        documento_descripcion: data.documento_descripcion,
        documento: data.documento,
        url: url,
        motivo_rechazo: data.motivo_rechazo,
      };
      commit("updateSolicitud", registroUpdate);
      return true;
    }
  } catch (error) {
    commit(
      "setSnackbar",
      {
        active: true,
        text: error.response.data.message,
        top: true,
        right: true,
        color: "error",
      },
      { root: true }
    );

    console.log("Error=====>", error);
    return false;
  }
};

// ---------------------------------------FILTERS
export const loadSolicitudesFilter = async ({ commit }, { estado, limit }) => {
  try {
    let respuesta;
    let limite = Number(limit) + 1;
    let url_consulta;
    if (estado == "TODOS") {
      url_consulta = `${path}?limit=${limite}`;
    } else {
      url_consulta = `${path}?estado=${estado}&limit=${limite}`;
    }

    respuesta = await axios.get(url_consulta, {
      headers: mClickHeader.headers,
    });
    respuesta = respuesta.data.data.solicitudes;

    // eslint-disable-next-line prettier/prettier
    if (respuesta.length > limit) {
      respuesta.pop(); //quitamos el ultimo valor del arreglo para que solo sean 10
      commit("setPaginacion", true);
    } else {
      commit("setPaginacion", false);
    }
    commit("setSolicitudesFiltradas", respuesta);
  } catch (error) {
    commit(
      "setSnackbar",
      {
        active: true,
        text: error.response.data.message,
        top: true,
        right: true,
        color: "error",
      },
      { root: true }
    );

    console.log("error ", error);
  }
};

export const paginacion = async ({ commit }, { limit, desde, estado }) => {
  try {
    let limite = Number(limit) + 1;
    let urlconsulta;
    if (estado == "SIN_ESTADO" || estado == "TODOS") {
      urlconsulta = `${path}?limit=${limite}&desde=${desde}`;
    } else {
      urlconsulta = `${path}?limit=${limite}&desde=${desde}&estado=${estado}`;
    }

    let respuesta = await axios.get(urlconsulta, {
      headers: mClickHeader.headers,
    });
    respuesta = respuesta.data.data.solicitudes;

    if (respuesta.length > limit) {
      respuesta.pop(); //quitamos el ultimo valor del arreglo para que solo sean 10
      commit("setPaginacion", true);
    } else {
      commit("setPaginacion", false);
    }
    commit("setSolicitudesFiltradas", respuesta);
  } catch (error) {
    commit(
      "setSnackbar",
      {
        active: true,
        text: error.response.data.message,
        top: true,
        right: true,
        color: "error",
      },
      { root: true }
    );

    console.log("error ", error);
  }
};

// -----------------------------------------------------------OPEN URL DOCUMENT
export const openUrlDocument = async ({ commit }, url) => {
  console.log(commit);
  console.clear();
  try {
    var { data } = await axios.get(url);
    if (data.success) {
      return data.data.url;
    }
    return null;
  } catch (error) {
    console.log("error ", error);
    return null;
  }
};
