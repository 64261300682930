import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import vueRut from "vue-rut";
import moment from "moment";
import axios from "axios";
import { rutValidator } from "vue-dni";
import { VueMaskDirective } from "v-mask";
import dateformat from "dateformat";
import VueTheMask from "vue-the-mask";
import "./components/global-components";
import "./scss/theme.scss";

import { version } from "../package.json";

require("dotenv").config();

// Vee-validate Config
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from "vee-validate";
import {
  required,
  min,
  max,
  email,
  confirmed,
  numeric,
  image,
} from "vee-validate/dist/rules";
import es from "vee-validate/dist/locale/es.json";
import vueCryptojsMin from "vue-cryptojs";
// Install Spanish
localize("es", es);
extend("email", email);
extend("required", required);
extend("min", min);
extend("max", max);
extend("confirmed", confirmed);
extend("numeric", numeric);
extend("image", image);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
//

Vue.use(VueTheMask);
dateformat.i18n = {
  dayNames: [
    "Dom",
    "Lun",
    "Mar",
    "Mie",
    "Jue",
    "Vie",
    "Sab",
    "Domingo",
    "Lunes",
    "Martes",
    "Miercoles",
    "Jueves",
    "Viernes",
    "Sabado",
  ],
  monthNames: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
};

Vue.directive("rut", rutValidator);
Vue.filter("formatDate", function (value, type) {
  if (value) {
    if (type !== "doc") return moment(new Date(value)).format("DD/MM/YYYY");
    else return dateformat(new Date(value), "d mmmm yyyy h:MM:ss TT");
  }
});

Vue.filter("formatCardDate", function (value) {
  if (value) {
    return moment(new Date(value)).format("MM/YYYY");
  }
});

Vue.filter("formatDatePay", function (value) {
  if (value) {
    return dateformat(new Date(value), "d mmmm yyyy");
  }
});

Vue.filter("UFPrice", function (value) {
  const v = value.toString().replace(".", ",");
  return v.slice(0, 3);
});

Vue.filter("formatDateDoc", function (value) {
  if (value) {
    return dateformat(new Date(value), "dd, mmmm dN, yyyy, h:MM:ss TT");
  }
});

Vue.filter("formatDateDocTwo", function (value) {
  if (value) {
    return dateformat(new Date(value), "dd mmmm  yyyy");
  }
});

Vue.filter("phone", function (phone) {
  return phone
    .replace(/[^0-9]/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
});

Vue.filter("formatBytes", function (bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
});

Vue.filter("formatDateUser", function (value) {
  if (value) {
    return dateformat(new Date(value), "dddd d mmmm  yyyy");
  }
});

Vue.filter("formatHourUser", function (value) {
  if (value) {
    return dateformat(new Date(value), "hh:MM TT");
  }
});

Vue.filter("rutFormat", function (rut) {
  if (rut) {
    let result = rut.slice(-4, -1) + "-" + rut.substr(rut.length - 1);
    for (let i = 4; i < rut.length; i += 3) {
      result = rut.slice(-3 - i, -i) + "." + result;
    }

    return result;
  }
});

Vue.filter("moneyFormat", function (val) {
  const formatClp = Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  });

  return formatClp.format(val);
});

Vue.directive("mask", VueMaskDirective);

axios.defaults.baseURL = process.env.VUE_APP_API_BFF_PRODUCTOS;

Vue.config.productionTip = false;
Vue.use(vueCryptojsMin);

Vue.use(vueRut);
new Vue({
  router,
  store,
  vuetify,
  created() {
    this.$store.commit("setVersion", version);

    const userString = sessionStorage.getItem("user");
    const userToken = sessionStorage.getItem("token");

    if (userString) {
      const userData = JSON.parse(userString);
      this.$store.commit("SET_USERDATA", userData);
    }
    if (userToken) {
      this.$store.commit("REFRESH_TOKEN", userToken);
    }

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("logout");
        }

        return Promise.reject(error);
      }
    );
  },
  render: (h) => h(App),
}).$mount("#app");
