export default [
  // MANTENEDORES (CRUDS) //
  {
    path: "/mantenedores",
    name: "Mantenedores",
    component: () => import("@/views/CRUDs/Index"),
    meta: {
      page: 100,
    },
  },
  {
    path: "/mantenedores/ramos",
    name: "branches-maintainer",
    component: () => import("@/views/CRUDs/Ramos"),
    meta: {
      page: 101,
    },
  },
  {
    path: "/mantenedores/paises",
    name: "countries-maintainer",
    component: () => import("@/views/CRUDs/Paises"),
    meta: {
      page: 102,
    },
  },
  {
    path: "/mantenedores/materia-asegurada",
    name: "insured-material-maintainer",
    component: () => import("@/views/CRUDs/MateriaAsegurada"),
    meta: {
      page: 103,
    },
  },
  {
    path: "/mantenedores/materia-asegurada/v2",
    name: "insured-material-v2-maintainer",
    component: () => import("@/views/CRUDs/InsuranceMateria.vue"),
    meta: {
      page: 104,
    },
  },
  {
    path: "/mantenedores/tipoProducto",
    name: "product-type-maintainer",
    component: () => import("@/views/CRUDs/TipoProducto"),
    meta: {
      page: 105,
    },
  },
  {
    path: "/mantenedores/monedas",
    name: "currency-maintainer",
    component: () => import("@/views/CRUDs/Monedas"),
    meta: {
      page: 106,
    },
  },
  {
    path: "/mantenedores/coberturas",
    name: "coverage-maintainer",
    component: () => import("@/views/CRUDs/Coberturas"),
    meta: {
      page: 107,
    },
  },
  {
    path: "/mantenedores/planes",
    name: "plan-maintainer",
    component: () => import("@/views/CRUDs/Planes"),
    meta: {
      page: 108,
    },
  },
  {
    path: "/mantenedores/segmentos",
    name: "segment-maintainer",
    component: () => import("@/views/CRUDs/Segmentos"),
    meta: {
      page: 109,
    },
  },
  {
    path: "/mantenedores/estados-solicitud",
    name: "application-status-maintainer",
    component: () => import("@/views/CRUDs/EstadosSolicitud"),
    meta: {
      page: 110,
    },
  },
  {
    path: "/mantenedores/vigencias",
    name: "validity-maintainer",
    component: () => import("@/views/CRUDs/Vigencias"),
    meta: {
      page: 111,
    },
  },
  {
    path: "/mantenedores/doc-anexo",
    name: "attached-document-maintainer",
    component: () => import("@/views/CRUDs/DocumentoAnexo"),
    meta: {
      page: 112,
    },
  },
  {
    path: "/mantenedores/doc-emision",
    name: "issuance-document-maintainer",
    component: () => import("@/views/CRUDs/DocumentoEmision"),
    meta: {
      page: 113,
    },
  },
  {
    path: "/mantenedores/polizas",
    name: "policy-maintainer",
    component: () => import("@/views/CRUDs/Polizas"),
    meta: {
      page: 114,
    },
  },
  {
    path: "/mantenedores/tipo-coberturas",
    name: "coverage-type-maintainer",
    component: () => import("@/views/CRUDs/TipoCobertuas"),
    meta: {
      page: 115,
    },
  },
  {
    path: "/mantenedores/tipo-contratante",
    name: "contractor-type-maintainer",
    component: () => import("@/views/CRUDs/TipoContratante"),
    meta: {
      page: 116,
    },
  },
  {
    path: "/mantenedores/financiera",
    name: "financial-maintainer",
    component: () => import("@/views/CRUDs/Financiera"),
    meta: {
      page: 117,
    },
  },
  {
    path: "/mantenedores/uso-producto",
    name: "product-use-maintainer",
    component: () => import("@/views/CRUDs/ProductUse"),
    meta: {
      page: 118,
    },
  },
  {
    path: "/mantenedores/corredor-de-seguros",
    name: "insurance-brokers-maintainer",
    component: () => import("@/views/CRUDs/insuranceBrokers.vue"),
    meta: {
      page: 119,
    },
  },
  {
    path: "/mantenedores/tipos-de-poliza",
    name: "policy-type-maintainer",
    component: () => import("@/views/CRUDs/policyType.vue"),
    meta: {
      page: 120,
    },
  },
  {
    path: "/mantenedores/colores-vehiculos",
    name: "colors-vehicle-maintainer",
    component: () => import("@/views/CRUDs/ColoresVehiculos.vue"),
    meta: {
      page: 121,
    },
  },
  {
    path: "/mantenedores/anios",
    name: "years-maintainer",
    component: () => import("@/views/CRUDs/Years.vue"),
    meta: {
      page: 122,
    },
  },
];
