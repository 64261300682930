import * as get from "./get";
import * as create from "./create";
import * as update from "./update";
import * as remove from "./delete";
import * as activate from "./activate";

//endpoints para guardar y crear un producto
export default {
  ...get,
  ...create,
  ...update,
  ...remove,
  ...activate,
};
