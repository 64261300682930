import axios from "axios";
import endpoints from "../../../../helpers/endpoints";
import { mClickHeader } from "@/store/resources/headers.js";

//METODOS DELETE
export const deleteRamos = async (context, id) => {
  const url = `${endpoints.cruds.ramos}/${id}`;
  try {
    const response = await axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data,
      info: "Se ha eliminado el ramo correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response.data,
      info: "Ha ocurrido un error al intentar eliminar el ramo",
    };
    throw rejectData;
  }
};

export const deleteColor = async ({ commit }, id) => {
  const url = `${endpoints.cruds.vehicleColors}/${id}`;
  try {
    let respuesta = await axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA delete color ", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

// Metodo Delete para los Segmentos de Definicion basica de producto
export const DeleteSegment = (context, id) => {
  // Payload => Id del segmento
  const url = `${endpoints.cruds.segmentos}/${id}`;
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha eliminado el Segmento correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: new Error(
            "Ha ocurrido un error al intentar eliminar el segmento."
          ),
        };
        reject(rejectData);
      });
  });
};
// Metodo Delete para los Uso de Producto de Definicion basica de producto
export const DeleteProductUse = (context, id) => {
  // Payload => Id del uso de producto
  const url = `${endpoints.cruds.usoProducto}/${id}`;
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha eliminado el uso del producto satisfactoriamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: new Error(
            "Ha ocurrido un error al intentar eliminar un Uso para el producto."
          ),
        };
        reject(rejectData);
      });
  });
};

export const DeleteInsuranceBroker = ({ dispatch }, payload = "") => {
  // Payload => Id del uso de producto
  const url = `${endpoints.cruds.corredor}/${payload}`;
  return new Promise((resolve, reject) => {
    axios
      .delete(url, { headers: mClickHeader.headers })
      .then(async () => {
        await dispatch("REQUEST_INSURANCE_BROKER");
        resolve("Se ha eliminado el corredor de seguros satisfactoriamente!");
      })
      .catch((error) => {
        const result = error.response.data.data;
        const message =
          result?.lista_mensaje?.[0]?.mensaje ??
          "Ha ocurrido un error al intentar eliminar un corredor de seguros.";
        reject(new Error(message));
      });
  });
};

export const deletePaises = async (context, id) => {
  const url = `${endpoints.cruds.paises}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data;
        resolve({
          result,
          info: "Se ha eliminado el País satisfactoriamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: "Ha ocurrido un error al intentar eliminar un País.",
        };
        reject(rejectData);
      });
  });
};
export const deleteMateria = async ({ commit }, id) => {
  const url = endpoints.cruds.InsureMateria;
  try {
    let respuesta = await axios.delete(`${url}/${id}`, {
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA delete materia ", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

export const deleteTipoProd = async (context, id) => {
  const url = `${endpoints.cruds.tipoProducto.base}/${id}`;
  try {
    const response = await axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha eliminado un tipo de producto correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar eliminar el tipo de producto",
    };
    throw rejectData;
  }
};

export const deleteMoneda = (context, id) => {
  const url = `${endpoints.cruds.monedas}/${id}`;
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url, headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data;
        resolve({
          result,
          info: "Se ha desactivado la moneda correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: "Ha ocurrido un error al intentar desactivar la moneda.",
        };
        reject(rejectData);
      });
  });
};

export const deleteCobertura = (context, id) => {
  const url = `${endpoints.cruds.coberturas}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: "Se ha eliminado la cobertura correctamente!",
        });
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar eliminar una cobertura",
        };
        reject(rejectData);
      });
  });
};

export const deletePlan = async ({ commit }, id) => {
  const url = `${endpoints.cruds.Plans}/${id}`;
  try {
    let respuesta = await axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA delete plan ", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};
export const deleteVigencia = async (context, id) => {
  const url = `${endpoints.cruds.vigencias}/${id}`;
  try {
    const response = await axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha eliminado la vigencia correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar eliminar una vigencia",
    };
    throw rejectData;
  }
};
export const deleteDocumentoAnexo = async (context, id) => {
  const url = `${endpoints.cruds.docAnexo}/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: "Se ha eliminado el documento correctamente!",
        });
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar eliminar el documento",
        };
        reject(rejectData);
      });
  });
};

export const deleteDocumentoEmision = async (context, id) => {
  try {
    let result = await axios.delete(`${endpoints.cruds.docEmision}/${id}`, {
      headers: mClickHeader.headers,
    });
    return {
      result,
      info: "Se ha eliminado un documento correctamente!",
    };
  } catch (error) {
    return {
      result: error.response,
      info: "Ha ocurrido un error al intentar eliminar un documento.",
    };
  }
};

export const deletePoliza = async (context, id) => {
  const url = `${endpoints.cruds.polizas}/${id}`;
  try {
    const response = await axios({
      url,
      method: "DELETE",
      headers: mClickHeader.headers,
    });
    return {
      result: response?.data,
      info: "Se ha eliminado una póliza correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response.data,
      info: "Ha ocurrido un error al intentar eliminar una póliza",
    };
    throw rejectData;
  }
};

export const deleteTipoCobertura = async (context, id) => {
  const url = `${endpoints.cruds.coberturaTipos2}/${id}`;
  try {
    const response = await axios.delete(url, { headers: mClickHeader.headers });
    return {
      result: response.data?.data,
      info: "Se ha eliminado un tipo de cobertura correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar eliminar el tipo de cobertura.",
    };
    throw rejectData;
  }
};

export const deleteTipoContratante = async (context, id) => {
  const url = `${endpoints.cruds.tipoContratante}/${id}`;
  try {
    const response = await axios({
      method: "DELETE",
      url,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha eliminado el tipo contratante correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar eliminar el tipo contratante",
    };
    throw rejectData;
  }
};
