import { endpoints } from "@/helpers/endpoints.js";
import { baseHttp } from "@/helpers/baseHttp.js";

export default {
  namespaced: "Plans",
  state: {
    endpoint: endpoints.cruds.Plans,
    items: [],
  },
  getters: {
    getItems: (state) => state.items,
    getEndpoint: (state) => state.endpoint,
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
  },
  actions: {
    GET({ commit, getters }) {
      const url = `${getters.getEndpoint}`;
      return baseHttp({
        url,
        method: "GET",
        commit,
        commitMethod: "setItems",
        messages: {
          error: "No se ha logrado recuperar el listado de planes.",
        },
        keys: {
          result: "planes",
        },
      });
    },

    ACTIVATE({ commit, getters }, payload) {
      const deactivate = payload?.deactivate ?? false;
      const url = `${getters.getEndpoint}/${deactivate ? "" : "activar/"}${
        payload.id
      }`;
      return baseHttp({
        url,
        method: deactivate ? "DELETE" : "PUT",
        commit,
        formData: payload.formData,
        messages: {
          success: `Se ha ${
            deactivate ? "desactivado" : "reactivado"
          } el plan correctamente!`,
          error: "Ha ocurrido un error al intentar actualizar el plan.",
        },
      });
    },
  },
};
