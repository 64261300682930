import Pages from "./pages.js";
import Cruds from "./crudPages.js";
import Integrator from "./integratorPages.js";
import Data from "./data.js";

export const pages = Pages;
export const crudsPages = Cruds;
export const integrator = Integrator;

export const data = Data;

export default data;
