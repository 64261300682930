import * as mutations from "./mutations";
import actions from "./actions";
import * as getters from "./getters";

export default {
  namespaced: true,
  state: {
    productos: [],
    segments: [],
    productUse: [],
    productTypeId: "",
    paginacion: false,
    company: localStorage.getItem("selectedCompany") || null,
    vigencias: localStorage.getItem("selectedValidity") || [],
    planes: localStorage.getItem("selectedPlans") || [],
    tarifas: [],
    companies: [],
    nemotecnicos: [],
    plantillas: [],
    statuslist: [],
    brokersList: [],
    behaviour: [],
    exclusivityList: [],
    loadingGetPlantillas: true,
    errorGetPlantillas: false,
  },
  actions,
  mutations,
  getters,
};
