export default [
  // PAGINAS DE AUTENTICACION
  {
    path: "/recover-password",
    name: "Recover",
    component: () => import("@/views/Authentication/RecoverAccount.vue"),
  },

  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Authentication/Login.vue"),
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: () => import("@/views/Authentication/InsertNewPassword.vue"),
  },
];
