import axios from "axios";
import endpoints from "@/helpers/endpoints";
import { mClickHeader } from "@/store/resources/headers.js";

const actions = {
  GetPaymentMethods({ commit }) {
    const path = endpoints.paymentMethods;
    return new Promise((resolve, reject) => {
      axios
        .get(path, mClickHeader)
        .then((response) => {
          const result = response.data.data.result;
          commit("setPaymentMethods", result);
          resolve(result);
        })
        .catch(() =>
          reject("No se ha logrado recuperar el listado de medios de pagos.")
        );
    });
  },
};

export default actions;
