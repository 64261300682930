export const setProductos = (state, payload) => {
  state.productos = payload;
};

export const setSegments = (state, payload) => {
  state.segments = payload;
};

export const resetSegments = (state) => {
  state.segments = [];
};

export const setPlantillasJsReport = (state, payload) => {
  state.plantillas = payload.plantillas;
  state.loadingGetPlantillas = payload.loadingGetPlantillas;
  state.errorGetPlantillas = payload.errorGetPlantillas;
};

export const setProductUse = (state, payload) => {
  state.productUse = payload;
};

export const setProductTypeId = (state, payload) => {
  state.productTypeId = payload;
};

export const setCompany = (state, payload) => {
  localStorage.setItem("selectedCompany", JSON.stringify(payload || null));
  state.company = payload;
};

export const setCompanyById = (state, payload) => {
  // Payload => Company ID
  const company = state.companies.find((e) => e.id === payload);
  if (company) {
    setCompany(state, company);
  }
};

export const setStatusList = (state, payload) => {
  state.statuslist = payload;
};

export const setBrokersList = (state, payload) => {
  state.brokersList = payload;
};

export const setBehaviour = (state, payload) => {
  state.behaviour = payload.sort((a, b) => a.codigo - b.codigo);
};

export const setTarifas = (state, payload) => {
  state.tarifas = payload;
};

export const setCompanies = (state, payload) => {
  state.companies = payload;
};

export const setNemotecnicos = (state, payload) => {
  state.nemotecnicos = payload;
};

export const setVigencias = (state, payload) => {
  localStorage.setItem("selectedValidity", JSON.stringify(payload || []));
  state.vigencias = payload;
};

export const setExclusivity = (state, payload) => {
  state.exclusivityList = payload;
};

export const setPlanes = (state, payload) => {
  localStorage.setItem("selectedPlans", JSON.stringify(payload || []));
  state.planes = payload;
};
