import axios from "axios";
import endpoints from "@/helpers/endpoints";
import { mClickHeader } from "@/store/resources/headers.js";

// METODOS DE ACTIVACION
// Metodo para activar un ramo
export const ActivateRamo = (context, payload) => {
  const { id } = payload;
  const url = `${endpoints.cruds.ramos}/activar/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data?.data;
        resolve({
          result,
          info: "Se ha re-activado el ramo correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: "Ha ocurrido un error al intentar activar un ramo",
        };
        reject(rejectData);
      });
  });
};

// Metodo para activar un Uso de Producto
export const ActivateProductUse = (context, payload) => {
  // Payload => Id del uso de producto
  const { id } = payload;
  const url = `${endpoints.cruds.usoProducto}/activar/${id}`;
  return new Promise((resolve, reject) => {
    axios({ method: "PUT", url, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha activado el uso del producto satisfactoriamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error(
            "Ha ocurrido un error al intentar activar un Uso para el producto."
          ),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

// Metodo para activar un corredor de seguros
export const ActivateInsuranceBroker = ({ dispatch }, payload = "") => {
  // Payload => Id del uso de producto
  const path = `${endpoints.cruds.corredor}/activar/${payload}`;
  return new Promise((resolve, reject) => {
    axios
      .put(path, { headers: mClickHeader.headers })
      .then(async () => {
        await dispatch("REQUEST_INSURANCE_BROKER");
        resolve("Se ha activado el corredor de seguros satisfactoriamente!");
      })
      .catch(() =>
        reject(
          new Error(
            "Ha ocurrido un error al intentar activar un corredor de seguros."
          )
        )
      );
  });
};

// ACTIVATE ACTION METHODS
export const ActivateMonedas = (context, id) => {
  // Payload => Id del uso de producto
  const url = `${endpoints.cruds.monedas}/activar/${id}`;
  return new Promise((resolve, reject) => {
    axios({ url, method: "PUT", headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data;
        resolve({
          result,
          info: "Se ha activado la moneda satisfactoriamente!",
        });
      })
      .catch((error) => {
        const rejectObject = {
          result: error.response.data,
          info: "Ha ocurrido un error al intentar activar la moneda.",
        };
        reject(rejectObject);
      });
  });
};

export const ActivateCountry = (context, id = "") => {
  // Payload => Id del uso de producto
  const url = `${endpoints.cruds.paises}/activar/${id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data;
        resolve({
          result,
          info: "Se ha activado el país satisfactoriamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: "Ha ocurrido un error al intentar activar el país.",
        };
        reject(rejectData);
      });
  });
};
