export default [
  // MANTENEDORES INTEGRATOR
  {
    path: "/integracion",
    name: "Integracion",
    meta: {
      page: 200,
    },
    component: () => import("@/views/CrudsIntegrator/Index"),
  },
  {
    path: "/integracion/empresas",
    name: "Empresas",
    component: () => import("@/views/CrudsIntegrator/Empresas"),
    meta: {
      page: 201,
    },
  },
  {
    path: "/integracion/servicios",
    name: "Servicios",
    component: () => import("@/views/CrudsIntegrator/Servicios"),
    meta: {
      page: 202,
    },
  },
  {
    path: "/integracion/homologaciones",
    name: "Homologaciones",
    meta: {
      page: 203,
    },
    component: () => import("@/views/CrudsIntegrator/Homologaciones"),
  },
  {
    path: "/integracion/formatos",
    name: "Formatos",
    component: () => import("@/views/CrudsIntegrator/Formatos"),
    meta: {
      page: 204,
    },
  },
  {
    path: "/integracion/matriz-qualitas",
    name: "matriz-qualitas",
    component: () => import("@/views/CrudsIntegrator/MatrizQualitas"),
    meta: {
      page: 205,
    },
  },
];
