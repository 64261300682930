import Vue from "vue";
import Vuetify from "vuetify/lib";
import { theme3 } from "./themes.js";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: theme3,
    },
  },
});
