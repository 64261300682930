export const loadSolicitud = (state, solicitudes) => {
  state.solicitudes = solicitudes;
};

export const setSolicitud = (state, solicitud) => {
  state.solicitudes.unshift(solicitud);
};

export const updateSolicitud = (state, solicitud) => {
  const idx = state.solicitudes.map((e) => e.id).indexOf(solicitud.id);
  state.solicitudes.splice(idx, 1, solicitud);
};

// ---------------------------------FILTRAR
export const setSolicitudesFiltradas = (state, solicitudes) => {
  solicitudes.length > 0
    ? ((state.solicitudes = []), (state.solicitudes = solicitudes))
    : (state.solicitudes = []);
};

// ------------------------------------PAGINACION
export const setPaginacion = (state, paginacion) => {
  state.paginacion = paginacion;
};
