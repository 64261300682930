import Vue from "vue";
import Vuex from "vuex";
import Vuetify from "@/plugins/vuetify";
import Authentication from "./Modules/Authentication/Authentication";
import Payment from "./Modules/Payment";

import Solicitudes from "./Modules/Solicitudes";
import NewProduct from "./Modules/NewProduct";
import EstadosSolicitud from "./Modules/EstadosSolicitud";
import Integrator from "./Modules/Integrator";
import Cruds from "./cruds.js";
import * as esText from "@/assets/lang/es/text.json";

import PageData from "@/router/data/index.js";
import Themes from "@/plugins/themes.js";

import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loadSpiner: false,
    alert: {},
    datoPropuesta: {},
    IdProducto: {},
    idRamo: "",
    documentEmit: [],
    deducible: [],
    nemotecnico: {},
    Producto: {},
    Convenio: "",
    solicitud: localStorage.getItem("solicitudSelected") || null,
    snackbar: {
      active: false,
      text: "",
      timeout: 5000,
      color: "",
      top: false,
      bottom: false,
      right: false,
      left: false,
      centered: false,
    },
    text: esText.data.general,
    version: "",
    page: {
      title: "Dashboard",
      breadcrumb: [],
    },
    pageData: PageData,
    theme: localStorage.getItem("m-theme"),
  },
  mutations: {
    setTheme(state, payload) {
      const key = `theme${payload}`;
      Vuetify.framework.theme.themes.light = Themes[key];
      localStorage.setItem("m-theme", payload);
      state.theme = payload;
    },
    statePiner(state, payLoad) {
      state.loadSpiner = payLoad;
    },
    Aplert(state, Alert) {
      state.alert = Alert;
    },
    setSolicitud(state, payload) {
      localStorage.setItem("solicitudSelected", JSON.stringify(payload));
      state.solicitud = payload;
    },
    productoId(state, IdProducto) {
      state.IdProducto = IdProducto;
    },
    ramosId(state, idRamo) {
      state.idRamo = idRamo;
    },
    DocEmit(state, documentEmit) {
      state.documentEmit = documentEmit;
    },
    Deducible(state, deducible) {
      state.deducible = deducible;
    },
    Nemotecnico(state, nemotecnico) {
      state.nemotecnico = nemotecnico;
    },
    Prodcut(state, Producto) {
      state.Producto = Producto;
      localStorage.setItem("produc", JSON.stringify(Producto));
    },
    Convenios(state, Convenio) {
      state.Convenio = Convenio;
    },
    setSnackbar(state, payload) {
      if ("text" in payload) state.snackbar.text = payload.text;
      if ("active" in payload) state.snackbar.active = payload.active;
      if ("timeout" in payload) state.snackbar.timeout = payload.timeout;
      if ("color" in payload) state.snackbar.color = payload.color;
      if ("top" in payload) state.snackbar.top = payload.top;
      if ("left" in payload) state.snackbar.left = payload.left;
      if ("right" in payload) state.snackbar.right = payload.right;
      if ("bottom" in payload) state.snackbar.bottom = payload.bottom;
      if ("centered" in payload) state.snackbar.centered = payload.centered;
    },
    setVersion(state, payload) {
      state.version = payload;
    },
    setPage(state, payload) {
      if ("title" in payload) state.page.title = payload.title;
      if ("breadcrumb" in payload) state.page.breadcrumb = payload.breadcrumb;
      if ("page" in payload) {
        let dataFiltered = state.pageData.find((e) => e.ref == payload.page);
        if (dataFiltered) {
          state.page.title = dataFiltered.title;
          state.page.breadcrumb = dataFiltered.breadcrumb;
          state.page.icon = dataFiltered.icon;
          state.page.hideTitle = dataFiltered.hideTitle;
          state.page.container = dataFiltered.container;
        }
      }
    },
  },
  getters: {
    GetTheme(state) {
      return state.theme;
    },
    GetSnackbar(state) {
      return state.snackbar;
    },
    GetText: (state) => state.text,
    getSpiner(state) {
      return state.loadSpiner;
    },
    getAlert(state) {
      return state.alert;
    },
    getSolicitud(state) {
      try {
        return JSON.parse(state.solicitud);
      } catch {
        return state.solicitud;
      }
    },
    getProductoId(state) {
      return state.IdProducto;
    },
    getRamoId(state) {
      return state.idRamo;
    },
    getDocEmit(state) {
      return state.documentEmit;
    },
    getDeducible(state) {
      return state.deducible;
    },
    getNemotecnico(state) {
      return state.nemotecnico;
    },
    getProduct(state) {
      return state.Producto;
    },
    getConvenio(state) {
      return state.Convenio;
    },
    GetVersion: (state) => state.version,
    GetPage(state) {
      return state.page;
    },
  },
  actions: {
    REQUEST_FILE({ commit }, url) {
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((r) => resolve(r.data))
          .catch(() => {
            commit("setSnackbar", {
              active: true,
              color: "warning",
              top: true,
              right: true,
              text: "No se ha logrado recuperar la imagen o archivo del servidor.",
            });
            reject(
              new Error(
                "No se ha logrado recuperar la imagen o archivo del servidor."
              )
            );
          });
      });
    },
  },
  modules: {
    Authentication,
    Payment,
    Solicitudes,
    NewProduct,
    EstadosSolicitud,
    Integrator,

    ...Cruds,
  },
});
