export const getAll = (state) => {
  return state;
};

export const homologacionesItems = (state) => {
  return state.itemsH;
};

export const matrizItems = (state) => {
  return state.itemsM;
};
