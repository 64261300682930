import axios from "axios";
import endpoints from "@/helpers/endpoints";
import { mClickHeader } from "@/store/resources/headers.js";

const policyTypePath = endpoints.cruds.tipoPoliza;

// REQUEST ACTION METHODS
export const REQUEST_POLICY_RANGE = ({ commit }, payload = {}) => {
  let path = `${endpoints.asignacionPoliza}?`;
  // Parametros adicionales
  path += [
    `pagina=${payload.page ? payload.page : 1}`,
    `limite=${payload.limit ? payload.limit : 10}`,
  ].join("&");
  // Search filter
  if (payload.filter && payload.filter !== "") {
    path += `&filtro=${payload.filter}`;
  }
  // Company Filter
  if (payload.company && payload.company !== "") {
    path += `&id_compania=${payload.company}`;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(path, { headers: mClickHeader.headers })
      .then((r) => {
        const data = r.data.data;
        // Guardar el listado en el Store
        commit("setPolicyRangeList", data.result);
        resolve({
          pagination: data._result,
          data: data.result,
        });
      })
      .catch(() => {
        reject(
          new Error(
            "No se ha logrado recuperar el listado de los rangos de poliza."
          )
        );
      });
  });
};

export const REQUEST_POLICY_TYPE = ({ commit }) => {
  const url = policyTypePath;
  return new Promise((resolve, reject) => {
    axios({ method: "GET", url, headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data.data?.result ?? [];
        // Guardar el listado en el Store
        commit("setPolicyTypesList", result);
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: new Error(
            "No se ha logrado recuperar el listado de los tipos de poliza."
          ),
        };
        reject(rejectData);
      });
  });
};

// CREATE ACTION METHODS
export const CreatePolicyType = (context, data = {}) => {
  // Payload => Objeto con ramo y uso_producto_descripcion
  const url = policyTypePath;
  return new Promise((resolve, reject) => {
    axios({ method: "POST", url, data, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha creado el tipo de póliza correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: new Error("No se ha logrado crear el nuevo tipo de póliza."),
        };
        reject(rejectData);
      });
  });
};

export const CreatePolicyRange = ({ dispatch }, payload = {}) => {
  // Payload => Objeto con ramo y uso_producto_descripcion
  const path = endpoints.asignacionPoliza;
  const formData = {
    ws: payload.ws ? payload.ws : {},
    compania: {
      id: payload.compania?.id ?? "",
      comportamiento: payload.compania?.comportamiento ?? 1,
    },
    producto: {
      id: payload.producto?.id ?? "",
      descripcion: payload.producto?.descripcion ?? "",
      tipo_poliza: payload.producto?.tipo_poliza ?? "COLECTIVA",
    },
    poliza: {
      colectiva: payload.poliza?.colectiva ?? "",
      individual: payload.poliza?.individual ?? [],
    },
    certificado: {
      desde: payload.certificado?.desde ?? "",
      hasta: payload.certificado?.hasta ?? "",
      actual: payload.certificado?.actual || payload.certificado?.desde || "",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .post(path, formData, { headers: mClickHeader.headers })
      .then(async () => {
        await dispatch("REQUEST_POLICY_RANGE", {
          company: payload.compania.id,
        });
        resolve("¡Se ha asignado la póliza satisfactoriamente!");
      })
      .catch(() => {
        reject(new Error("No se ha logrado asignar la póliza."));
      });
  });
};

// UPDATE ACTION METHODS
export const UpdatePolicyType = (context, payload = {}) => {
  // Payload => Objeto con ramo y uso_producto_descripcion
  const { id, ...data } = payload;
  const url = `${policyTypePath}/${id}`;
  return new Promise((resolve, reject) => {
    axios({ method: "PUT", url, data, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha actualizado el tipo de póliza correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: new Error("No se ha logrado actualizar el tipo de póliza."),
        };
        reject(rejectData);
      });
  });
};

export const UpdatePolicyRange = (
  { dispatch },
  payload = { id: "", data: {} }
) => {
  // Payload => Objeto con ramo y uso_producto_descripcion
  const path = `${endpoints.asignacionPoliza}/${payload.id}`;
  const formData = {
    ws: payload.data.ws,
    compania: {
      id: payload.data.compania.id,
      comportamiento: payload.data.compania.comportamiento,
    },
    producto: {
      id: payload.data.producto.id,
      descripcion: payload.data.producto.descripcion,
      tipo_poliza: payload.data.producto.tipo_poliza,
    },
    poliza: {
      colectiva: payload.data.poliza.colectiva,
      individual: payload.data.poliza.individual,
    },
    certificado: {
      desde: payload.data.certificado.desde,
      hasta: payload.data.certificado.hasta,
      actual:
        payload.data?.certificado?.actual ||
        payload.data?.certificado?.desde ||
        "",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .put(path, formData, { headers: mClickHeader.headers })
      .then(async () => {
        await dispatch("REQUEST_POLICY_RANGE", {
          company: payload.data.compania.id,
        });
        resolve("¡Se ha actualizado la póliza correctamente!");
      })
      .catch(() => {
        reject(new Error("No se ha logrado actualizar la póliza."));
      });
  });
};

// DELETE ACTION METHODS
export const DeletePolicyType = (context, id = "") => {
  // Payload => Id del tipo de poliza
  const url = `${policyTypePath}/${id}`;
  return new Promise((resolve, reject) => {
    axios({ method: "DELETE", url, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha eliminado el tipo de póliza satisfactoriamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: new Error(
            "Ha ocurrido un error al intentar eliminar un tipo de póliza."
          ),
        };
        reject(rejectData);
      });
  });
};

export const DeletePolicyRange = ({ commit }, payload = "") => {
  // Payload => Id de la poliza
  const path = `${endpoints.asignacionPoliza}/${payload}`;
  return new Promise((resolve, reject) => {
    axios
      .delete(path, { headers: mClickHeader.headers })
      .then((response) => {
        console.log("RESPONSE ==>", response, commit);
        resolve("Se ha eliminado la póliza satisfactoriamente!");
      })
      .catch(() =>
        reject(
          new Error("Ha ocurrido un error al intentar eliminar una póliza.")
        )
      );
  });
};

// ACTIVATE ACTION METHODS
export const ActivatePolicyType = (context, payload = "") => {
  // Payload => Id del uso de producto
  const { id } = payload;
  const url = `${policyTypePath}/activar/${id}`;
  return new Promise((resolve, reject) => {
    axios({ method: "PUT", url, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          info: "Se ha activado el tipo de póliza satisfactoriamente!",
          result: response.data?.data,
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: new Error(
            "Ha ocurrido un error al intentar activar un tipo de póliza."
          ),
        };
        reject(rejectData);
      });
  });
};

export const ActivatePolicyRange = ({ commit }, payload = "") => {
  // Payload => Id del uso de producto
  const path = `${endpoints.asignacionPoliza}/activar/${payload}`;
  return new Promise((resolve, reject) => {
    axios
      .put(path, { headers: mClickHeader.headers })
      .then((response) => {
        console.log("RESPONSE ==>", response, commit);
        resolve("Se ha activado la póliza correctamente!");
      })
      .catch(() =>
        reject(
          new Error("Ha ocurrido un error al intentar activar una póliza.")
        )
      );
  });
};
