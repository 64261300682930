import Vue from "vue";
import VueRouter from "vue-router";

import Cruds from "./cruds";
import Auth from "./auth";
import Integrations from "./integrations";
import Loader from "@/views/Loader";

Vue.use(VueRouter);

const routes = [
  ...Auth,
  {
    path: "/",
    component: Loader,
  },

  {
    path: "/dashboard",
    component: () => import("@/layouts/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      ...Cruds,
      ...Integrations,
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home/Home.vue"),
        meta: {
          page: "0",
        },
      },
      {
        path: "/productos",
        name: "products",
        component: () => import("../views/Products/ListProduct.vue"),
        meta: {
          page: 1,
        },
      },
      {
        path: "/solicitudes",
        name: "requests",
        component: () => import("@/views/Products/Solicitudes.vue"),
        meta: {
          page: 2,
        },
      },
      {
        path: "/asignacion-de-poliza",
        name: "policy-range",
        component: () => import("@/views/Products/PolicyRange.vue"),
        meta: {
          page: 3,
        },
      },
      {
        path: "/companias",
        name: "companies",
        component: () => import("@/views/CRUDs/Companias"),
        meta: {
          page: 4,
        },
      },
      {
        path: "/marcas-modelos",
        name: "brandmodels",
        component: () => import("@/views/CRUDs/brandAndModels.vue"),
        meta: {
          page: 5,
        },
      },

      // Paginas internas de productos
      {
        path: "/nuevo-producto",
        name: "NewProduct",
        meta: {
          page: 10,
        },
        component: () => import("../views/Products/NewProduct"),
      },
      {
        path: "/Editar-producto",
        name: "EditProduct",
        meta: {
          page: 10,
        },
        component: () => import("../views/Products/NewProduct"),
      },
      {
        name: "Enconstruccion",
        path: "/enconstruccion",
        component: () => import("../views/Products/Processing.vue"),
      },
      {
        path: "/seleccionar-accion",
        name: "SelectAction",
        component: () => import("../views/Products/ProductSelect"),
      },
      {
        path: "/registrar-solicitud",
        name: "RegisterDemand",
        component: () => import("../views/Products/RegisterDemand"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = sessionStorage.getItem("user");
  // const token_temporal = sessionStorage.getItem("token_temporal");
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next({ name: "Login" });
  }
  next();
});

export default router;
