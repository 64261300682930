import colors from "vuetify/lib/util/colors";

export const theme0 = {
  primary: {
    base: colors.blueGrey.darken1,
    darken2: colors.blueGrey.darken2,
    darken3: colors.blueGrey.darken3,
    darken4: colors.blueGrey.darken4,
    lighten5: colors.blueGrey.lighten5,
  },
  info: {
    base: colors.blueGrey.lighten3,
  },
  success: {
    base: colors.blueGrey.base,
  },
  error: {
    base: colors.red.base,
  },
};

export const theme1 = {
  primary: {
    base: colors.deepPurple.darken1,
    darken2: colors.deepPurple.darken2,
    darken3: colors.deepPurple.darken3,
    darken4: colors.deepPurple.darken4,
    lighten5: colors.deepPurple.lighten5,
  },
  info: {
    base: colors.deepPurple.accent2,
  },
  success: {
    base: colors.purple.accent4,
  },
  error: {
    base: colors.red.base,
  },
};

export const theme2 = {
  primary: {
    base: colors.teal.darken1,
    darken2: colors.teal.darken2,
    darken3: colors.teal.darken3,
    darken4: colors.teal.darken4,
    lighten5: colors.teal.lighten5,
  },
  info: {
    base: colors.teal.accent3,
  },
  success: {
    base: colors.teal.accent4,
  },
  error: {
    base: colors.red.base,
  },
};

// DEFAULT BLUE THEME
export const theme3 = {
  primary: {
    base: "#2160df",
    darken2: colors.blue.darken2,
    darken3: colors.blue.darken3,
    darken4: "#223A65",
    lighten5: colors.blue.lighten5,
  },
  info: {
    base: "#00c2ff",
  },
  success: {
    base: colors.green.base,
  },
  error: {
    base: colors.red.base,
  },
};

// YELLOW THEME
export const theme4 = {
  primary: {
    base: colors.amber.darken3,
    darken2: colors.amber.darken4,
    darken3: colors.orange.darken3,
    darken4: colors.orange.darken4,
    lighten5: colors.amber.lighten5,
  },
  info: {
    base: colors.yellow.accent4,
  },
  success: {
    base: colors.orange.lighten2,
  },
  error: {
    base: colors.red.base,
  },
};

// RED THEME
export const theme5 = {
  primary: {
    base: "#7D0A0A", //colors.red.darken2,
    darken2: colors.red.darken2,
    darken3: colors.red.darken3,
    darken4: colors.red.darken4,
    lighten5: colors.red.lighten5,
  },
  info: {
    base: "#BF3131", // colors.red.base,
  },
  success: {
    base: "#EAD196", // colors.green.darken4,
  },
  error: {
    base: colors.red.base,
  },
};

// PINK THEME
export const theme6 = {
  primary: {
    base: colors.pink.darken4,
    darken2: colors.pink.darken2,
    darken3: colors.pink.darken3,
    darken4: "#332941", // colors.pink.accent4,
    lighten5: colors.pink.lighten5,
  },
  info: {
    base: colors.pink.accent2,
  },
  success: {
    base: colors.pink.lighten1,
  },
  error: {
    base: colors.red.base,
  },
};

export default {
  theme0,
  theme1,
  theme2,
  theme3,
  theme4,
  theme5,
  theme6,
};
