export default [
  {
    ref: "201",
    path: "Empresas",
    disabled: false,
    title: "Empresas",
    name: "Empresas",
    text: "Configurador de empresas",
    type: "box",
    icons: {
      menu: "domain",
      index: "domain",
    },
  },
  {
    ref: "202",
    path: "Servicios",
    disabled: false,
    title: "Servicios",
    name: "Servicios",
    text: "Configurador de servicios",
    type: "box",
    icons: {
      menu: "account-wrench",
      index: "account-wrench",
    },
  },
  {
    ref: "203",
    path: "Homologaciones",
    disabled: false,
    title: "Homologaciones",
    name: "Homologaciones",
    text: "Configurador de homologaciones",
    type: "box",
    icons: {
      menu: "approximately-equal",
      index: "approximately-equal",
    },
  },
  {
    ref: "204",
    path: "Formatos",
    disabled: false,
    title: "Formatos",
    name: "Formatos",
    text: "Configurador de formatos",
    type: "box",
    icons: {
      menu: "folder-information",
      index: "folder-information",
    },
  },
  {
    ref: "205",
    path: "matriz-qualitas",
    disabled: false,
    title: "Matriz Qualitas",
    name: "Matriz Qualitas",
    text: "Datos adicionales para integración compañía",
    type: "box",
    icons: {
      menu: "matrix",
      index: "matrix",
    },
  },
];
