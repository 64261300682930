import axios from "axios";
import { mClickHeader } from "@/store/resources/headers.js";
import { endpoints } from "@/helpers/endpoints.js";

//METODOS GET
export const getRamos = () => {
  const url = endpoints.cruds.ramos;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data.result;
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: "No se ha logrado recuperar los ramos del servidor.",
        };
        reject(rejectData);
      });
  });
};

/* export const getTipoProductosAll = async () => {
  const url = endpoints.cruds.tipoProducto.base
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: "GET",
      headers: mClickHeader.headers
    })
      .then((res) => {
        resolve(res.data.data.result);
        console.clear();
      })
      .catch((err) => {
        reject(err);
      });
  });
}; */

export const getPaises = () => {
  const url = endpoints.cruds.paises;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data.result);
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "No se ha logrado recuperar el listado de paises.",
        };
        reject(rejectData);
      });
  });
};

export const getMoneda = () => {
  const url = endpoints.cruds.monedas;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data.result);
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "No se ha logrado recuperar el listado de monedas del servidor",
        };
        reject(rejectData);
      });
  });
};

export const getImpuesto = () => {
  const url = endpoints.cruds.impuestos;

  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "No se ha logrado recuperar el listado de impuestos.",
        };
        reject(rejectData);
      });
  });
};

export const REQUEST_PRODUCT_STATUS = ({ commit }) => {
  const path = endpoints.cruds.estadoSolicitud;
  return new Promise((resolve, reject) => {
    axios(path, { headers: mClickHeader.headers })
      .then((r) => {
        commit("setStatusList", r.data.data.result);
        resolve("ok");
      })
      .catch(() => {
        reject(
          new Error("No se ha logrado recuperar los estados del producto.")
        );
      });
  });
};

export const REQUEST_INSURANCE_BROKER = ({ commit }, payload = {}) => {
  // Payload => Paginacion
  let url = `${endpoints.cruds.corredor}?`;
  // Parametros adicionales
  url += [
    `pagina=${payload.page ? payload.page : 1}`,
    `limite=${payload.limit ? payload.limit : 10}`,
  ].join("&");
  if (payload.filter && payload.filter !== "") {
    url += `&filtro=${payload.filter}`;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: mClickHeader.headers })
      .then((r) => {
        const data = r.data.data;
        // Guardar el listado en el Store
        commit("setBrokersList", data.result);
        resolve(data._result);
      })
      .catch(() => {
        reject(
          new Error(
            "No se ha logrado recuperar el listado de corredores de seguros."
          )
        );
      });
  });
};

export const REQUEST_INSURANCE_BROKER_BY_ID = ({ commit }, payload = "") => {
  // Payload => Id del corredor de seguros
  let url = `${endpoints.cruds.corredor}/${payload}`;
  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers: mClickHeader.headers })
      .then((r) => {
        const data = r.data.data;
        console.log("RESPUESTA DATA CORREDOR DE SEGUROS", r, commit);
        resolve(data.result);
      })
      .catch(() => {
        reject(
          new Error(
            "No se ha logrado recuperar el corredor de seguro seleccionado."
          )
        );
      });
  });
};

export const REQUEST_PRODUCT_USE = ({ commit }) => {
  const url = endpoints.cruds.usoProducto;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data.result;
        commit("setProductUse", result);
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          info: "No se ha logrado recuperar el uso de producto del servidor.",
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

export const REQUEST_BEHAVIOUR = ({ commit }) => {
  const url = endpoints.cruds.behaviour;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((r) => {
        commit("setBehaviour", r.data.data.result);
        resolve("ok");
      })
      .catch(() => {
        reject("No se ha logrado recuperar el listado de los comportamientos.");
      });
  });
};

export const GetProductUseByRamo = ({ commit }, id = "") => {
  return new Promise((resolve, reject) => {
    //Payload => Ramo Id
    if (id) {
      const url = `${endpoints.cruds.usoProducto}/ramo/${id}`;
      axios({ method: "GET", url, headers: mClickHeader.headers })
        .then((r) => {
          commit("setProductUse", r.data.data.result);
          resolve("ok");
        })
        .catch(() => {
          commit("setProductUse", []);
          reject(
            new Error(
              "No se ha logrado recuperar el uso de producto del servidor."
            )
          );
        });
    } else {
      commit("setProductUse", []);
      resolve("ok");
    }
  });
};

export const getMateriaAll = async () => {
  const url = endpoints.cruds.InsureMateria;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: url,
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data.result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCobertura = async () => {
  const url = endpoints.cruds.coberturas;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPlanesall = async () => {
  const url = endpoints.cruds.Plans;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data.planes);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getDocAnexo = async () => {
  const url = endpoints.cruds.docAnexo;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data.result);
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          info: "Ha ocurrido un error al intentar recuperar la lista de documentos anexos.",
          result,
        };
        reject(rejectData);
      });
  });
};
export const getDatosVentas = async () => {
  const url = endpoints.newProduct.datosVenta;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data.datos_venta);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getPolizas = async () => {
  const url = endpoints.cruds.polizas;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve(response.data.data?.result ?? []);
      })
      .catch((error) => {
        const result = error.response?.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar recuperar el listado de polizas.",
        };
        reject(rejectData);
      });
  });
};

export const GetCompanies = async ({ commit, dispatch }) => {
  const path = endpoints.cruds.companies;
  return new Promise((resolve, reject) => {
    axios({
      url: path,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        const result = res.data.data.result;
        result.forEach(async (element) => {
          let companyLogo = sessionStorage.getItem(
            `companylogo-n${element.codigo}`
          );
          if (companyLogo) {
            element.logo = companyLogo;
          } else {
            let { data } = await dispatch("REQUEST_FILE", element.logo, {
              root: true,
            });
            sessionStorage.setItem(`companylogo-n${element.codigo}`, data.url);
            element.logo = data.url;
          }
        });
        commit("setCompanies", result);
        resolve("ok");
      })
      .catch(() => {
        reject(
          new Error("Ha ocurrido un error al intentar recuperar las Compañias.")
        );
      });
  });
};

export const GetCompaniesWithoutLogo = async ({ commit }) => {
  const path = endpoints.cruds.companies;
  return new Promise((resolve, reject) => {
    axios({
      url: path,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        const result = res.data.data.result;
        commit("setCompanies", result);
        resolve("ok");
      })
      .catch(() => {
        reject(
          new Error("Ha ocurrido un error al intentar recuperar las Compañias.")
        );
      });
  });
};

export const getTipoComision = async () => {
  const url = endpoints.newProduct.tipoComisiones;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getTipoTarifas = ({ commit }, payload = null) => {
  let path = endpoints.newProduct.tipoTarifas;
  if (payload) {
    const params = [];
    if (
      "id_empresa" in payload &&
      payload.id_empresa &&
      payload.id_empresa !== ""
    ) {
      params.push(`id_empresa=${payload.id_empresa}`);
    }
    path = `${path}?${params.join("&")}`;
  }
  return new Promise((resolve, reject) => {
    axios({
      url: path,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        commit("setTarifas", res.data.data.tarifas);
        resolve("ok");
      })
      .catch(() => {
        reject(new Error("No se ha logrado recuperar las tarifas."));
      });
  });
};

export const getTipoCoberturas = async () => {
  const url = endpoints.cruds.coberturaTipos;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        const rejectData = {
          info: "Ha ocurrido un error al intentar recuperar el listado de tipos de coberturas.",
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

export const getTipoVigencias = async () => {
  const url = endpoints.cruds.vigencias;
  return new Promise((resolve, reject) => {
    axios({
      url: `${url}/tipos`,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const REQUEST_NEMOTECNICOS = async ({ commit }, payload) => {
  const path = `${endpoints.productos.nemotecnico}${payload.id}`;
  return new Promise((resolve, reject) => {
    axios
      .put(path, payload.data, { headers: mClickHeader.headers })
      .then((r) => {
        commit("setNemotecnicos", r.data.data.result);
        resolve("ok");
      })
      .catch(() => {
        reject(
          new Error(
            "Ha ocurrido un error al intentar generar los nemotecnicos."
          )
        );
      });
  });
};

export const getTipoContratante = () => {
  const url = endpoints.cruds.tipoContratante;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve(response.data.data.result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response?.data,
          info: "No se ha logrado recuperar el listado de contratantes.",
        };
        reject(rejectData);
      });
  });
};

export const getConveniosContratante = async () => {
  const url = endpoints.newProduct.canalesContratante;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getProducts = ({ commit }, payload = null) => {
  let path = endpoints.productos.base;
  if (payload) {
    if (payload.map) {
      path = endpoints.productos.map;
    }
    let params = [
      `pagina=${payload.page ? payload.page : 1}`,
      `limite=${payload.limit ? payload.limit : 10}`,
    ];
    if (payload.filter && payload.filter !== "") {
      params.push(`filtro=${payload.filter}`);
    }
    if (payload.excluir && payload.excluir !== "") {
      params.push(`excluir=${payload.excluir}`);
    }
    path = `${path}?${params.join("&")}`;
  }
  return new Promise((resolve, reject) => {
    axios({
      url: path,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        commit("setProductos", response.data.data.result);
        resolve({
          result: response.data.data.result,
          pagination: response.data.data._result || null,
        });
      })
      .catch((error) => {
        commit(
          "setSnackbar",
          {
            active: true,
            text: error.response.data.message,
            top: true,
            right: true,
            color: "error",
          },
          { root: true }
        );

        reject(new Error("No se ha logrado recuperar el listado de productos"));
      });
  });
};

export const getProductoID = async ({ commit }, id) => {
  const url = `${endpoints.productos.base}/${id}`;
  try {
    let respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA productos by id", respuesta);
    return respuesta.data.data.result;
  } catch (error) {
    return error.response;
  }
};

export const getProductsByCompany = ({ commit }, payload) => {
  // Payload => Id de compania
  const path = `${endpoints.productos.byCompany}${payload}`;
  return new Promise((resolve, reject) => {
    axios({
      url: path,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        commit("setProductos", response.data.data.result);
        resolve("ok");
      })
      .catch(() => {
        reject(
          new Error("No se encontraron productos para la compañia seleccionada")
        );
      });
  });
};

export const getPlantillasJsReport = () => {
  const url = endpoints.jsReport;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        resolve(response.data.data.plantillas);
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "No se ha logrado recuperar las plantillas.",
        };
        reject(rejectData);
      });
  });
};

export const requestSegment = ({ commit }, id = null) => {
  let url = endpoints.cruds.segmentos;
  if (id) {
    url = `${url}/${id}`;
  }
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data.result;
        commit("setSegments", result);
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: "No se ha logrado recuperar el listado de segmentos",
        };
        reject(rejectData);
      });
  });
};

export const getSegmentByRamo = ({ commit }, id) => {
  //Payload => ID Ramo
  const path = `${endpoints.cruds.segmentos}/${id}/ramo`;
  return new Promise((resolve, reject) => {
    commit("resetSegments");
    axios
      .get(path, { headers: mClickHeader.headers })
      .then((r) => {
        commit("setSegments", r.data.data.result);
        resolve("ok");
      })
      .catch((error) => {
        const message =
          "Ha ocurrido un error al intentar recuperar los Segmentos del ramo.";
        const rejectData = {
          result: error.response?.data,
          info: message,
        };
        reject(rejectData);
      });
  });
};

// Get materia asegurada by Ramo Id
export const getMateria = async ({ commit }, id) => {
  const url = endpoints.cruds.InsureMateria;
  try {
    let respuesta = await axios.get(`${url}/${id}/tipo-producto`, {
      headers: mClickHeader.headers,
    });
    console.log("RESPUESTA Materia", respuesta, commit);
    return respuesta.data.data.result;
  } catch (error) {
    return error.response;
  }
};

// Get tipo de producto by Ramo Id
export const getTipoProductos = async () => {
  const url = endpoints.cruds.tipoProducto.base;
  try {
    const response = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    return response.data?.data?.result ?? [];
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar recuperar el listado de tipos de productos.",
    };
    throw rejectData;
  }
};

export const getTipoProductoBySegment = async (context, id) => {
  const url = endpoints.cruds.tipoProducto.segment.replace(":id", id);
  try {
    let response = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    return response.data.data.result;
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar recuperar el listado de tipos de productos.",
    };
    throw rejectData;
  }
};

export const getPlanes = async ({ commit }, id) => {
  const url = `${endpoints.cruds.Plans}/ramo/${id}`;
  try {
    let respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    console.log("RESPUESTA Materia", respuesta, commit);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

export const getIDViPlan = async ({ commit }, payload) => {
  // Payload => Listado de planes seleccionados
  const url = `${endpoints.cruds.Plans}/`;
  const data = [];
  try {
    for (let p in payload) {
      let respuesta = await axios({
        method: "GET",
        url: `${url}${payload[p].plan}`,
        headers: mClickHeader.headers,
      });
      data.push(respuesta.data.data.result[0]);
    }
    console.log(commit, "RESPUESTA planes by id", data);
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getExclusivity = ({ commit }) => {
  const path = endpoints.newProduct.tiposExclusividad;
  return new Promise((resolve, reject) => {
    axios
      .get(path, { headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data.data.result;
        commit("setExclusivity", result);
        resolve("ok");
      })
      .catch(() =>
        reject("No se ha logrado recuperar el listado de tipo de exclusivdad")
      );
  });
};

export const getVigencias = () => {
  const url = endpoints.cruds.vigencias;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data?.vigencias;
        resolve(result);
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: new Error(
            "Ha ocurrido un error al intentar recuperar las vigencias del servidor."
          ),
        };
        reject(rejectData);
      });
  });
};

export const getVigenciaById = async (context, id) => {
  const url = endpoints.cruds.vigencias;
  const data = [];
  try {
    for (let v in id) {
      const response = await axios.get(`${url}/${id[v].id_vigencia}`, {
        headers: mClickHeader.headers,
      });
      data.push(response.data.data.result);
    }
    return data;
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar recuperar las vigencias.",
    };
    throw rejectData;
  }
};

export const getDocEmision = () => {
  const url = endpoints.cruds.docEmision;
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data.result;
        resolve(result);
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar recuperar el listado de documentos.",
        };
        reject(rejectData);
      });
  });
};

export const getIdDocE = async ({ commit }, id) => {
  const url = `${endpoints.cruds.docEmision}/`;
  const data = [];
  try {
    for (let e in id) {
      let respuesta = await axios({
        method: "GET",
        url: `${url}${id[e].id_emision}`,
        headers: mClickHeader.headers,
      });
      data.push(respuesta.data.data.result[0]);
    }

    console.log(commit, "RESPUESTA document-emision by id", data);
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getIdDocA = async ({ commit }, id) => {
  const data = [];
  const url = `${endpoints.cruds.docAnexo}/`;
  try {
    for (let a in id) {
      let respuesta = await axios({
        method: "GET",
        url: `${url}${id[a].id_anexo}`,
        headers: mClickHeader.headers,
      });      
      data.push(respuesta.data.data.result);
    }

    console.log(commit, "RESPUESTA document-anexo by id", data);
    return data;
  } catch (error) {
    return error.response;
  }
};

export const getIdComañia = async ({ commit }, id) => {
  const url = `${endpoints.cruds.companies}/${id}`;
  try {
    let respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA compañia by id", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

export const getIdPolizas = async ({ commit }, id) => {
  const url = `${endpoints.cruds.polizas}/${id}`;
  try {
    let respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA Polizas by id", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

export const getIdContratante = async ({ commit }, id) => {
  const url = `${endpoints.cruds.tipoContratante}/${id}`;
  try {
    let respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA tipo contratante by id", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

export const getIdFinanciera = async ({ commit }, id) => {
  const url = `${endpoints.cruds.financiera}/${id}`;
  try {
    let respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA financiera by id", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

export const getIdConvenios = async ({ commit }, id) => {
  const url = `${endpoints.newProduct.canales}/${id}/contratante`;
  try {
    let respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA convenio by id", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

export const GetTextos = async ({ commit }, data) => {
  var id_product = data.id_producto;
  const url = `${endpoints.productos.textos}${id_product}/producto`;
  try {
    let respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA texto by id", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

export const getTextosIDproductIDdocument = async ({ commit }, data) => {
  var id_product = data.id_producto;
  var id_document = data.id_documento_emision;
  const url = `${endpoints.productos.textos}${id_product}/producto/${id_document}/texto`;
  try {
    let respuesta = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    console.log(
      commit,
      "RESPUESTA texto by id_product, id_document",
      respuesta
    );
    return respuesta.data.data.result;
  } catch (error) {
    return error.response;
  }
};

export const getOpcionesAdicionales = async () => {
  const url = endpoints.productos.infoAdicional;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        const result = res.data.data.menu.sort((a, b) =>
          a.nombre.localeCompare(b.nombre)
        );
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getFinancieras = async () => {
  const url = endpoints.cruds.financiera;
  return new Promise((resolve, reject) => {
    axios({
      url,
      method: "GET",
      headers: mClickHeader.headers,
    })
      .then((res) => {
        const result = res.data.data.result.sort((a, b) =>
          a.financiera_descripcion.localeCompare(b.financiera_descripcion)
        );
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getParametrizacionByDominio = (context, dominio) => {
  const url = `${endpoints.dominio}${dominio}`;
  return new Promise((resolve, reject) => {
    axios({ url, method: "GET", headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data.data;
        resolve(result);
      })
      .catch((error) => {
        reject(
          error?.response?.data ??
            "No se ha logrado recuperar el listado de parametrizacion."
        );
      });
  });
};
