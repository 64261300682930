import axios from "axios";
import { mClickHeader } from "@/store/resources/headers.js";

export const GET = async ({ commit, getters }) => {
  const url = getters.getEndpoint;
  let result,
    responseData = {};
  try {
    const { data } = await axios({
      method: "GET",
      url,
      headers: mClickHeader.headers,
    });
    responseData = data.data.result;
    commit("setItems", responseData);
    return responseData;
  } catch (error) {
    result = error.response.data;
    responseData = {
      result,
      info: "No se ha logrado recuperar el listado de estados solicitudes",
    };
    throw responseData;
  }
};

export const POST = async ({ getters }, data) => {
  const url = getters.getEndpoint;
  let result,
    responseData = {};
  try {
    const response = await axios({
      method: "POST",
      data,
      url,
      headers: mClickHeader.headers,
    });
    result = response.data.data?.result ?? [];
    responseData = {
      result,
      info: "Se ha creado un nuevo estado de solicitud satisfactoriamente!",
    };
    return responseData;
  } catch (error) {
    result = error.response.data;
    responseData = {
      info: "Ha ocurrido un error al intentar crear un nuevo estado de solicitud.",
      result,
    };
    throw responseData;
  }
};

export const PUT = async ({ getters }, data) => {
  const url = `${getters.getEndpoint}/${data.id}`;
  let result,
    responseData = {};
  try {
    const response = await axios({
      method: "PUT",
      url,
      data,
      headers: mClickHeader.headers,
    });
    result = response.data.data.result;
    responseData = {
      result,
      info: "Se ha actualizado el estado de solicitud correctamente!",
    };
    return responseData;
  } catch (error) {
    result = error.response.data;
    responseData = {
      result,
      info: "Ha ocurrido un error al intentar actualizar el estado de solicitud",
    };
    throw responseData;
  }
};

export const DELETE = async ({ getters }, id) => {
  const url = `${getters.getEndpoint}/${id}`;
  let result,
    responseData = {};
  try {
    const response = await axios({
      url,
      method: "DELETE",
      headers: mClickHeader.headers,
    });
    result = response.data;
    responseData = {
      result,
      info: "Se ha eliminado un esto de solicitud correctamente!",
    };
    return responseData;
  } catch (error) {
    result = error.response.data;
    responseData = {
      result,
      info: "Ha ocurrido un error al intentar eliminar un estado de solicitud.",
    };
    throw responseData;
  }
};
