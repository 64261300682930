import { endpoints } from "@/helpers/endpoints.js";
import { baseHttp } from "@/helpers/baseHttp.js";

export default {
  namespaced: true,
  state: {
    items: [],
    endpoint: endpoints.cruds.vehicleColors,
  },
  getters: {
    getItems(state) {
      return state.items;
    },
    getEndpoint(state) {
      return state.endpoint;
    },
  },
  mutations: {
    setItems(state, payload) {
      state.items = payload;
    },
  },
  actions: {
    GET({ commit, getters }, pagination = null) {
      const url = `${getters.getEndpoint}`;
      let params = {};
      if (pagination) {
        params = {
          limit: pagination?.limit,
          page: pagination?.page,
        };
      }
      return baseHttp({
        url,
        method: "GET",
        params,
        commit,
        commitMethod: "setItems",
        messages: {
          error: "No se ha logrado recuperar el listado de colores.",
        },
        keys: {
          result: "result",
        },
      });
    },
    POST({ commit, getters }, formData) {
      const url = `${getters.getEndpoint}`;
      return baseHttp({
        url,
        method: "POST",
        commit,
        formData,
        messages: {
          success: "Se ha creado un nuevo color satisfactoriamente!",
          error: "Ha ocurrido un error al intentar guardar un nuevo color.",
        },
      });
    },
    PUT({ commit, getters }, payload) {
      const url = `${getters.getEndpoint}/${payload.id}`;
      return baseHttp({
        url,
        method: "PUT",
        commit,
        formData: payload,
        messages: {
          success: "Se ha actualizado el color correctamente!",
          error: "Ha ocurrido un error al intentar actualizar el color.",
        },
      });
    },
    DELETE({ commit, getters }, id) {
      const url = `${getters.getEndpoint}/${id}`;
      return baseHttp({
        url,
        method: "DELETE",
        commit,
        messages: {
          success: "Se ha eliminado el color correctamente!",
          error: "Ha ocurrido un error al intentar eliminar un color.",
        },
      });
    },
  },
};
