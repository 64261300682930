<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  created() {
    if (this.theme) {
      this.$store.commit("setTheme", this.theme);
    }
  },
  mounted() {
    var url = window.location.href.split("/");
    this.$store.commit("SET_URL_CONVENIO", url[2]);
  },
  computed: {
    theme() {
      return this.$store.getters.GetTheme;
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
  display: initial !important;
}
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.35);
  transition: background-color 1s;
  border-radius: 8px;
}
::-webkit-scrollbar-button {
  background-color: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-corner {
  background-color: rgba(0, 0, 0, 0);
}
</style>
