export const endpoints = {
  companies: `/company`,
  documentos: `/documentos`,
  cruds: {
    brandAndModels: {
      base: `/vehiculos`,
      brand: `/vehiculos/marcas`,
      model: `/vehiculos/modelos`,
      logic: "/logico",
    },
    Years: `/anios`,
    coberturas: `/coberturas`,
    coberturaTipos: `/coberturas/tipos`,
    corredor: `/corredor`,
    docAnexo: `/documentos-anexos`,
    docEmision: `/documentos-emision`,
    estadoSolicitud: `/estados-solicitud`,
    financiera: `/financieras`,
    InsureMateria: `/materia-asegurada`,
    InsureMateriaV2: `/materias-aseguradas`,
    tipoProducto: {
      base: `/tipos-productos`,
      segment: `/tipos-productos/:id/segmento`,
    },
    monedas: `/monedas`,
    paises: `/paises`,
    Plans: `/planes`,
    polizas: `/polizas`,
    ramos: `/ramos`,
    segmentos: `/segmentos`,
    coberturaTipos2: `/tipo-coberturas`,
    tipoContratante: `/tipo-contratante`,
    vehicleColors: `/colores-vehiculos`,
    impuestos: `/configuraciones/parametros/impuesto_paises`,
    tipoPoliza: `/tipo-polizas`,
    usoProducto: `/uso-producto`,
    vigencias: `/vigencias`,
    companies: `/company`,
    behaviour: `/comportamiento`,
  },
  integrations: {
    homologaciones: `/integrator/homologaciones`,
    hCargaMasiva: `/homologaciones-integrator/carga-masiva`,
    services: `/integrator/servicios`,
    empresas: `/integrator/empresas`,
    formatos: `/integrator/formatos`,
    matriz: {
      companies: `/company/all`,
      productos: `/productos/all-product/`,
      deducibles: `/productos/deducible/`,
      marcas: `/vehiculos/marcasMIC`,
      modelos: `/vehiculos/modelosMIC/`,
      matriz: `/matriz-integracion-compania`,
    },
  },
  dominio: `/parametrizacion/dominio/`,
  //jsReport: `/jsreport`,
  asignacionPoliza: `/asignacion-poliza`,
  productos: {
    base: `/productos`,
    nemotecnico: `/productos/nemotecnico/`,
    map: `/productos/all/map`,
    byCompany: `/productos/producto-by-compania/`,
    textos: `/productos/textos/`,
    infoAdicional: `/productos/informacion-adicional/opciones`,
  },
  newProduct: {
    planes: `/planes`,
    datosVenta: `/datos-venta`,
    tipoComisiones: `/tipo-comisiones`,
    tipoTarifas: `/tipo-tarifas`,
    canalesContratante: `/canales/contratante`,
    tiposExclusividad: `/tipos-exclusividad`,
    canales: `/canales`,
  },
  paymentMethods: `/medios-pago`,
  solicitudes: `/solicitudes`,
};

export default endpoints;
