export default [
  {
    ref: "1-1",
    title: "Módulos de Productos",
    type: "title",
  },
  {
    ref: "1",
    path: "products",
    disabled: false,
    title: "Listado de productos",
    name: "Productos",
    text: "Administra toda la información de los productos generados en el sistema.",
    type: "box",
    icons: {
      menu: "archive",
      index: "archive-settings-outline",
    },
  },
  {
    ref: "2",
    path: "requests",
    disabled: false,
    title: "Listado de solicitudes",
    name: "Solicitudes",
    text: "Ver y gestionar todas las solicitudes generadas en el sistema.",
    type: "box",
    icons: {
      menu: "receipt-text",
      index: "receipt-text-outline",
    },
  },
  {
    ref: "3",
    path: "policy-range",
    disabled: false,
    title: "Asignación de Pólizas",
    name: "Pólizas",
    text: "Ver el listado de asignaciones de pólizas y sus distribuciones.",
    type: "box",
    icons: {
      menu: "file-check-outline",
      index: "file-sign",
    },
  },
  {
    ref: "4",
    path: "companies",
    disabled: false,
    title: "Compañías",
    name: "Compañías",
    text: "Administra todas las compañías para su uso en todos los sistemas.",
    type: "box",
    icons: {
      menu: "office-building",
      index: "domain",
    },
  },
  {
    ref: "5",
    path: "brandmodels",
    disabled: false,
    title: "Marcas y Módelos",
    name: "Marcas y Módelos",
    text: "Listado de marcas y módelos utilizados por todos los sistemas.",
    type: "box",
    icons: {
      menu: "animation",
      index: "expand-all-outline",
    },
  },
  {
    ref: "2-1",
    type: "divider",
  },
  {
    ref: "1-2",
    title: "Configuración",
    type: "title",
  },
  {
    ref: "100",
    path: "Mantenedores",
    disabled: false,
    title: "Gestor de contenido",
    name: "Mantenedores",
    text: "Gestionar el contenido y las herramientas para productos (Mantenedores).",
    type: "box",
    icons: {
      menu: "wrench-cog-outline",
      index: "table-cog",
    },
  },
  {
    ref: "200",
    path: "Integracion",
    disabled: false,
    title: "Gestor de integraciones",
    name: "Integraciones",
    text: "Gestionar todo lo relacionado a las integraciones con diferentes sistemas.",
    type: "box",
    icons: {
      menu: "integrated-circuit-chip",
      index: "integrated-circuit-chip",
    },
  },
];
