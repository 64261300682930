import MarcasModelos from "@/store/Modules/MarcasModelos";
import Companias from "./Modules/Companias";
import Polizas from "./Modules/Polizas";
import MateriaAsegurada from "./Modules/CRUDS/MateriaAsegurada";
import Years from "@/store/Modules/CRUDS/Years.js";
import Plans from "@/store/Modules/CRUDS/Plans.js";
import VehicleColors from "@/store/Modules/CRUDS/VehicleColors.js";
import Financiera from "@/store/Modules/CRUDS/Financiera.js";

export default {
  MarcasModelos,
  Polizas,
  Companias,
  MateriaAsegurada,
  Years,
  Plans,
  VehicleColors,
  Financiera,
};
