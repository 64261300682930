export const homeBreadCrumb = {
  text: "Home",
  disabled: false,
  href: "/",
};

export const crudBreadCrumb = {
  text: "Mantenedores",
  disabled: false,
  href: "/mantenedores",
};

export const integratorBreadCrumb = {
  text: "Integraciones",
  disabled: false,
  href: "/integracion",
};
