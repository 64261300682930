import Vue from "vue";

const components = {
  //Button
  PButton: () => import("@/components/Button/Btn-Principal"),
  SButton: () => import("@/components/Button/Btn-Secundario"),

  // Inputs
  TextField: () => import("@/components/Inputs/TextField"),
  Input: () => import("@/components/Inputs/Input"),
  SelectField: () => import("@/components/Inputs/SelectField"),
  CheckBox: () => import("@/components/Checkbox/Checkbox.vue"),

  // Table
  SimpleTable: () => import("@/components/Tables/SimpleTable"),
};

Object.entries(components).forEach(([name, component]) =>
  Vue.component(name, component)
);
