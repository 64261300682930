import axios from "axios";
import { mClickHeader } from "@/store/resources/headers.js";
import { endpoints } from "@/helpers/endpoints.js";

// METODOS PARA ACTUALIZAR
export const UpdatedataBasica = async ({ commit }, payload) => {
  const path = `${endpoints.productos.base}/${payload.id}/definicion-basica`;
  const formData = payload.data;
  return new Promise((resolve) => {
    axios
      .put(path, formData, {
        headers: mClickHeader.headers,
      })
      .then((respuesta) => {
        console.log(
          commit,
          "RESPUESTA DATA ACTUALIZAR DEFINICION BASICA",
          respuesta
        );
        resolve(respuesta);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const Updateplanes = async ({ commit }, payload) => {
  const path = `${endpoints.productos.base}/${payload.id}/coberturas-planes`;
  // Payload => Id del producto y formulario del paso 2
  var cobertura = [];
  var plan = [];
  let asistencias = [];
  var id_cobertura = "";
  for (var i = 0; i < payload.data.coberturas.length; i++) {
    id_cobertura =
      payload.data.coberturas[i].cobertura ||
      payload.data.coberturas[i].id_cobertura ||
      payload.data.coberturas[i]._id;

    cobertura.push({
      id_cobertura: id_cobertura,
      monto_distribucion: payload.data.coberturas[i].monto_distribucion,
      deducible: payload.data.coberturas[i].deducible,
      monto_asegurado: payload.data.coberturas[i].monto_asegurado,
      order: payload.data.coberturas[i].order || 0,
      // distribucion_prima: payload.data.coberturas[i].distribucion_prima,
      // aplica: payload.data.coberturas[i].aplica,
      titulo: payload.data.coberturas[i].titulo,
    });
  }
  for (var j in payload.data.plan) {
    if (payload.data.plan[j]) {
      plan.push({ plan: payload.data.plan[j].plan });
    }
  }
  if (payload.data.asistencias) {
    asistencias = Array.isArray(payload.data.asistencias)
      ? payload.data.asistencias
      : [payload.data.asistencias];
  }
  const formData = {
    distribucion_prima:
      payload.data && payload.data.distribucion_prima
        ? payload.data.distribucion_prima
        : "",
    coberturas: cobertura,
    planes: plan,
    asistencias: asistencias,
    exclusividad: payload.data.exclusividad || null,
  };
  return new Promise((resolve) => {
    axios
      .put(path, formData, { headers: mClickHeader.headers })
      .then((respuesta) => {
        console.log(commit, "RESPUESTA DATA ACTUALIZAR PLANES", respuesta);
        resolve(respuesta);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const Updatatributos = async ({ commit }, payload) => {
  const path = `${endpoints.productos.base}/${payload.id}/atributos`;
  // Payload => {data: Datos del formulario, id: Id del producto}
  let documentos = [];
  let anexos = [];
  let vigencias = [];
  for (var i = 0; i < payload.data.docEmision.length; i++) {
    documentos.push({
      documento: payload.data.docEmision[i].documento_descripcion,
      id_emision: payload.data.docEmision[i]._id,
    });
  }
  for (var j = 0; j < payload.data.docAnexo.length; j++) {
    anexos.push({
      anexo: payload.data.docAnexo[j].documento,
      id_anexo: payload.data.docAnexo[j]._id,
    });
  }

  for (var v = 0; v < payload.data.vigencia.length; v++) {
    if (payload.data.vigencia[v] == "") {
      payload.data.vigencia.shift();
    }
    vigencias.push({
      vigencia: payload.data.vigencia[v].vigencia_descripcion,
      meses: payload.data.vigencia[v].meses,
      vigencia_descripcion: payload.data.vigencia[v].vigencia_descripcion,
      id_vigencia: payload.data.vigencia[v]._id,
    });
  }
  const formData = {
    tiene_carencia: payload.data.tiene_carencia,
    meses: payload.data.meses,
    vigencias: vigencias,
    documentos_emision: payload.data.docEmision.length > 0 ? documentos : "",
    documentos_anexo: anexos,
  };
  return new Promise((resolve) => {
    axios
      .put(path, formData, { headers: mClickHeader.headers })
      .then((respuesta) => {
        console.log("RESPUESTA DATA ACTUALIZAR ATRIBUTOS", respuesta, commit);
        resolve(respuesta);
      })
      .catch((error) => {
        resolve(error.response);
      });
  });
};

export const UpdateTarifas = async ({ commit }, payload) => {
  const path = `${endpoints.productos.base}/${payload.id}/compania-tarifas`;
  // Data de envio
  const formData = {
    poliza: payload.data.poliza
      ? payload.data.poliza.poliza_descripcion || ""
      : "",
    id_poliza: payload.data.poliza ? payload.data.poliza._id || "" : "",
    nemotecnico_vigencia: payload.data.nemotecnico_vigencia
      ? payload.data.nemotecnico_vigencia
      : false,
    nemotecnico: payload.data.nemotecnico ? payload.data.nemotecnico : [],
    tarifas: {
      tarifa:
        payload.data.tarifa && payload.data.tarifa.tarifa
          ? payload.data.tarifa.tarifa.toString()
          : "",
      tarifa_descripcion:
        payload.data.tarifa && payload.data.tarifa.tarifa_descripcion
          ? payload.data.tarifa.tarifa_descripcion
          : "",
    },
    contratante: {
      tipo_contratante:
        payload.data.contratante && payload.data.contratante.tipo_contratante
          ? payload.data.contratante.tipo_contratante
          : "",

      codigo:
        payload.data.contratante && payload.data.contratante.codigo
          ? payload.data.contratante.codigo
          : "",
      rut_contratante:
        payload.data.contratante && payload.data.contratante.codigo
          ? payload.data.contratante.codigo.toUpperCase() == "OTRO"
            ? payload.data.rutContratante
            : payload.data.contratante.codigo.toUpperCase() == "FINANCIERA"
            ? payload.data.financiera.numero_documento
            : ""
          : "",

      contratante:
        payload.data.contratante && payload.data.contratante.codigo
          ? payload.data.contratante.codigo.toUpperCase() == "OTRO"
            ? payload.data.nombreContratante || ""
            : payload.data.contratante.codigo.toUpperCase() == "FINANCIERA"
            ? payload.data.financiera
              ? payload.data.financiera.financiera_descripcion
              : ""
            : payload.data.convenios
            ? payload.data.convenios.canal_descripcion
            : ""
          : "",

      id_contratante:
        payload.data.contratante && payload.data.contratante.codigo
          ? payload.data.contratante.codigo.toUpperCase() == "FINANCIERA"
            ? payload.data.financiera
              ? payload.data.financiera.financiera
              : ""
            : payload.data.contratante.codigo.toUpperCase() == "CONVENIO"
            ? payload.data.convenios
              ? payload.data.convenios.canal
              : ""
            : ""
          : "",
    },
  };
  // Condiciones
  const preNemo = [];
  formData.nemotecnico.forEach((e) => {
    preNemo.push({
      ref: e.ref,
      deducible: e.deducible,
      plan: e.plan,
      plan_descripcion: e.plan_descripcion,
      ramo: e.ramo,
      subproducto: e.subproducto,
      vigencia: e.vigencia,
      vigencia_descripcion: e.vigencia_descripcion,
      vigencia_meses: e.vigencia_meses,
    });
    if ("ref" in e) {
      // En el caso de que exista la variable ref en los objetos, se elimina
      delete e.ref;
    }
  });
  return new Promise((resolve) => {
    axios
      .put(path, formData, { headers: mClickHeader.headers })
      .then((respuesta) => {
        console.log("RESPUESTA DATA ACTUALIZAR Compañia", respuesta, commit);
        resolve(respuesta);
      })
      .catch((error) => {
        error.response.data.nemotecnico = preNemo;
        resolve(error.response);
      });
  });
};

export const UpdataCompañiatextos = async ({ commit }, payload) => {
  try {
    let respuesta = await axios.put(
      `${endpoints.productos.base}/textos`,
      payload,
      { headers: mClickHeader.headers }
    );
    console.log("RESPUESTA DATA ACTUALIZAR Campañia textos", respuesta, commit);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};
export const UpdataCertificacion = async ({ commit }, data) => {
  let id = data.id;
  var tipos = [];
  for (var i = 0; i < data.dataForm_5.comisionType.length; i++) {
    tipos.push({ tipo: data.dataForm_5.comisionType[i] });
  }
  var data_2 = {
    comision: data.dataForm_5.comision,
    canal: data.dataForm_5.canal,
    tipo_comision: tipos,
  };
  try {
    let respuesta = await axios.put(
      `${endpoints.productos.base}/${id}/certificacion`,
      data_2,
      { headers: mClickHeader.headers }
    );
    console.log("RESPUESTA DATA ACTUALIZAR certificacion", respuesta, commit);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};

//METODOS UPDATE INDIVIDUAL
export const UpdateRamo = (context, payload) => {
  const { id, ...data } = payload;
  const url = `${endpoints.cruds.ramos}/${id}`;
  return new Promise((resolve, reject) => {
    axios({ method: "PUT", data, url, headers: mClickHeader.headers })
      .then((response) => {
        const result = response.data?.data;
        resolve({
          result,
          info: "Se ha actualizado el ramo correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: "Ha ocurrido un erro al intentar eliminar el ramo",
        };
        reject(rejectData);
      });
  });
};

export const UpdatePais = async (context, data) => {
  const url = `${endpoints.cruds.paises}/${data.id}`;
  try {
    const response = await axios({
      method: "PUT",
      url,
      data,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data.data,
      info: "Se ha actualizado el país correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response.data,
      info: "Ha ocurrido un error al intentar actualizar un país",
    };
    throw rejectData;
  }
};
export const UpdateMateria = async ({ commit }, data) => {
  const url = endpoints.cruds.InsureMateria;
  try {
    let id = data.materia;
    let respuesta = await axios.put(`${url}/${id}`, data, {
      headers: mClickHeader.headers,
    });
    console.log(commit, "RESPUESTA editar materia ", respuesta);
    return respuesta;
  } catch (error) {
    return error.response;
  }
};
export const UpdateTipoProd = async (context, payload) => {
  const { id, ...data } = payload;
  const url = `${endpoints.cruds.tipoProducto.base}/${id}`;
  try {
    const response = await axios({
      method: "PUT",
      url,
      data,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha actualizado el tipo de producto correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.info,
      info: "Ha ocurrido un error al intentar actualizar el tipo de producto",
    };
    throw rejectData;
  }
};

export const UpdateProductUse = (context, payload) => {
  // Payload => Objeto con ramo y uso_producto_descripcion
  const { id, ...data } = payload;
  const url = `${endpoints.cruds.usoProducto}/${id}`;
  return new Promise((resolve, reject) => {
    axios({ method: "PUT", url, data, headers: mClickHeader.headers })
      .then((response) => {
        resolve({
          result: response.data?.data,
          info: "Se ha actualizado el uso de producto correctamente!",
        });
      })
      .catch((error) => {
        const rejectData = {
          info: new Error("No se ha logrado actualizar el uso del producto."),
          result: error.response?.data,
        };
        reject(rejectData);
      });
  });
};

export const UpdateInsuranceBroker = (
  { dispatch },
  payload = { id: "", data: {} }
) => {
  // Payload => Objeto con ramo y uso_producto_descripcion
  const url = `${endpoints.cruds.corredor}/${payload.id}`;
  const formData = {
    corredor_descripcion: payload.data.corredor_descripcion,
    tipo_documento: payload.data.tipo_documento,
    numero_documento: payload.data.numero_documento,
    poliza_garantia: payload.data.poliza_garantia,
    poliza_responsabilidad: payload.data.poliza_responsabilidad,
    diversificacion_produccion: payload.data.diversificacion_produccion,
    porcentaje_corredor: payload.data.porcentaje_corredor
      ? payload.data.porcentaje_corredor
      : 0,
    actualiza_logo: payload.data.actualiza_logo,
    actualiza_firma: payload.data.actualiza_firma,
    logo: {
      mime: payload.data.logo.mime ? payload.data.logo.mime : "",
      nombre_archivo: payload.data.logo.nombre_archivo
        ? payload.data.logo.nombre_archivo
        : "",
      archivo: payload.data.logo.archivo ? payload.data.logo.archivo : "",
    },
    firma: {
      mime: payload.data.firma.mime ? payload.data.firma.mime : "",
      nombre_archivo: payload.data.firma.nombre_archivo
        ? payload.data.firma.nombre_archivo
        : "",
      archivo: payload.data.firma.archivo ? payload.data.firma.archivo : "",
    },
  };
  return new Promise((resolve, reject) => {
    axios
      .put(url, formData, { headers: mClickHeader.headers })
      .then(async () => {
        await dispatch("REQUEST_INSURANCE_BROKER");
        resolve("Se ha actualizado el corredor de seguros correctamente!");
      })
      .catch(() => {
        reject(
          new Error("No se ha logrado actualizar el corredor de seguros.")
        );
      });
  });
};

export const UpdateMoneda = async (context, data) => {
  const url = `${endpoints.cruds.monedas}/${data.id}`;
  try {
    const response = await axios({
      method: "PUT",
      data,
      url,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data.data,
      info: "Se ha actualizado la moneda correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response.data,
      info: "Ha ocurrido un error al actualizar la moneda",
    };
    throw rejectData;
  }
};
export const UpdateCobertura = (context, payload) => {
  const url = `${endpoints.cruds.coberturas}/${payload.id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      data: payload.data,
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: "Se ha actualizado la cobertura correctamente!",
        });
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar actualizar la cobertura",
        };
        reject(rejectData);
      });
  });
};
export const UpdatePlan = ({ commit }, data) => {
  const url = `${endpoints.cruds.Plans}/${data.id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url,
      data,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data;
        commit("setPlanes", result);
        resolve({ result, info: "Se ha actualizado el plan correctamente!" });
      })
      .catch((error) => {
        const rejectData = {
          result: error.response.data,
          info: new Error("No se ha logrado actualizar el plan."),
        };
        reject(rejectData);
      });
  });
};
export const UpdateSegmento = async (context, payload) => {
  const { id, ...data } = payload;
  const url = `${endpoints.cruds.segmentos}/${id}`;
  try {
    const response = await axios({
      method: "PUT",
      data,
      url,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha actualizado un segmento correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response.data,
      info: "Ha ocurrido un error al intentar actualizar un segmento",
    };
    throw rejectData;
  }
};
export const UpdateVigencia = async (context, payload) => {
  const { id, ...data } = payload;
  const url = `${endpoints.cruds.vigencias}/${id}`;
  try {
    const response = await axios({
      method: "PUT",
      url,
      data,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha actualizado la vigencia correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar actualizar la vigencia",
    };
    throw rejectData;
  }
};
export const UpdateDocumentoAnexo = (context, payload) => {
  const url = `${endpoints.cruds.docAnexo}/${payload.id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      data: payload.data,
      url,
      headers: mClickHeader.headers,
    })
      .then((response) => {
        const result = response.data.data;
        resolve({
          result,
          info: "Se ha actualizado un documento anexo correctamente!",
        });
      })
      .catch((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar actualizar un documento.",
        };
        reject(rejectData);
      });
  });
};

export const UpdateDocumentoEmision = (context, data) => {
  const url = `${endpoints.cruds.docEmision}/${data.id}`;
  return new Promise((resolve, reject) => {
    axios({
      method: "PUT",
      url,
      data,
      headers: mClickHeader.headers,
    }).then((response) => {
      const result = response.data.data;
      resolve({
        result,
        info: "Se ha actualizado el documento correctamente!",
      }).reject((error) => {
        const result = error.response.data;
        const rejectData = {
          result,
          info: "Ha ocurrido un error al intentar actualizar el documento",
        };
        reject(rejectData);
      });
    });
  });
};
export const UpdatePoliza = async (context, payload) => {
  const { id, ...data } = payload;
  const url = `${endpoints.cruds.polizas}/${id}`;
  try {
    const response = await axios({
      data,
      url,
      method: "PUT",
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha actualizado una póliza correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar actualizar una póliza",
    };
    throw rejectData;
  }
};
export const UpdateTipoCobertura = async (context, payload) => {
  const { id, ...data } = payload;
  const url = `${endpoints.cruds.coberturaTipos2}/${id}`;
  try {
    const response = await axios({
      method: "PUT",
      data,
      url,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha actualizado el tipo de cobertura correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar actualizar el tipo de cobertura.",
    };
    throw rejectData;
  }
};
export const UpdateNemotecnico = async ({ commit }, data) => {
  try {
    let id = data.id;
    let respuesta = await axios.put(
      `${endpoints.productos.base}/${id}/compania-tarifas`,
      {
        id_compania: data.id_compania,
        poliza: data.poliza,
        id_poliza: data.id_poliza,
        rut_contratante: data.rut_contratante,
        contratante: data.contratante,
        nemotecnico: [
          {
            deducible: data.deducible,
            subproducto: data.subproducto,
            plan_descripcion: data.plan_descripcion,
            ramo: data.ramo,
            plan: data.plan,
          },
        ],
        tarifas: [
          {
            tarifa_descripcion: data.tarifa_descripcion,
            tarifa: data.tarifa,
          },
        ],
      },
      { headers: mClickHeader.headers }
    );
    console.log(commit, "RESPUESTA tipo cobertura ", respuesta);
    return respuesta;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const UpdateTipoContratante = async (context, payload) => {
  const { id, ...data } = payload;
  const url = `${endpoints.cruds.tipoContratante}/${id}`;
  try {
    const response = await axios({
      method: "PUT",
      url,
      data,
      headers: mClickHeader.headers,
    });
    return {
      result: response.data?.data,
      info: "Se ha actualizado el tipo de contratante correctamente!",
    };
  } catch (error) {
    const rejectData = {
      result: error.response?.data,
      info: "Ha ocurrido un error al intentar actualizar un tipo de contratante",
    };
    throw rejectData;
  }
};
