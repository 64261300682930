export const brandModelKeys = {
  brand: {
    id: "_id",
    description: "marca_descripcion",
    code: "marca",
    items: "modelos",
    active: "eliminado",
  },
  model: {
    id: "_id",
    description: "modelo_descripcion",
    code: "modelo",
    main: "marca",
    active: "eliminado",
  },
};

export default {
  brandModelKeys,
};
